import { createSelector } from "reselect";
import moment from "moment";
import "moment/locale/es";
import { ROLES_RAW } from "../../constants/permissions";
moment().locale("es");

/* APP */
export const isUserAuthorized = createSelector(
  (state) => state.authorization,
  (authorization) => authorization
);
export const getCurrentRoute = createSelector(
  (state) => state.currentRoute,
  (currentRoute) => currentRoute
);
export const getCurrentRouteTitle = createSelector(
  getCurrentRoute,
  (route) => route.title
);
export const getSideMenuState = createSelector(
  (state) => state.app,
  (app) => app.isCollapsedSideMenu
);
export const getCurrentTheme = createSelector(
  (state) => state.currentTheme,
  (currentTheme) => currentTheme
);

/* PROFILE */
export const getUserProfile = createSelector(
  (state) => state.profile,
  (profile) => profile
);
export const currentUserId = createSelector(
  (state) => state.profile,
  (profile) => profile && profile.id
);
export const getCurrentUserRole = createSelector(getUserProfile, (profile) =>
  !profile ? null : (ROLES_RAW.includes(profile.role) ? profile.role : null)
);

/* NOTIFICATIONS STATE */
export const getNotificationsState = createSelector(
  (state) => state.notifications,
  (notifications) => notifications
);

/* USERS */
export const getUsers = createSelector(
  (state) => state.users,
  (users) => users
);
export const getUsersForm = createSelector(
  (state) => state.usersForm,
  (form) => form
);

/* ALERT */
export const getAlerts = createSelector(
  (state) => state.alerts,
  (alerts) => alerts
);
export const getAlertsForm = createSelector(
  (state) => state.alertsForm,
  (form) => form
);

/* COURSES */
export const getCourses = createSelector(
  (state) => state.courses,
  (courses) => courses
);
export const getCoursesForm = createSelector(
  (state) => state.coursesForm,
  (form) => form
);

/* CLASSROOMS */
export const getClassrooms = createSelector(
  (state) => state.classrooms,
  (classrooms) => classrooms
);
export const getClassroomsForm = createSelector(
  (state) => state.classroomsForm,
  (form) => form
);

/* SUBJECTS */
export const getSubjects = createSelector(
  (state) => state.subjects,
  (subjects) => subjects
);
export const getSubjectsForm = createSelector(
  (state) => state.subjectsForm,
  (form) => form
);

/* BOOKINGS */
export const getBookings = createSelector(
  (state) => state.bookings,
  (bookings) => bookings
);
export const getBookingsForm = createSelector(
  (state) => state.bookingsForm,
  (form) => form
);

/* GRADES */
export const getGrades = createSelector(
  (state) => state.grades,
  (grades) => grades
);
export const getGradesForm = createSelector(
  (state) => state.gradesForm,
  (form) => form
);

/* RESOURCES */
export const getResources = createSelector(
  (state) => state.resources,
  (resources) => resources
);
export const getResourcesForm = createSelector(
  (state) => state.resourcesForm,
  (form) => form
);

/* STUDIES */
export const getStudies = createSelector(
  (state) => state.studies,
  (studies) => studies
);
export const getStudiesForm = createSelector(
  (state) => state.studiesForm,
  (form) => form
);

/* BOOKTYPES */
export const getBooktypes = createSelector(
  (state) => state.booktypes,
  (booktypes) => booktypes
);
export const getBooktypesForm = createSelector(
  (state) => state.booktypesForm,
  (form) => form
);

/* EDITIONS */
export const getEditions = createSelector(
  (state) => state.editions,
  (editions) => editions
);
export const getEditionsForm = createSelector(
  (state) => state.editionsForm,
  (form) => form
);

/* EDITIONXSUBJECTS */
export const getEditionxsubjects = createSelector(
  (state) => state.editionxsubjects,
  (editionxsubjects) => editionxsubjects
);
export const getEditionxsubjectsForm = createSelector(
  (state) => state.editionxsubjectsForm,
  (form) => form
);

/* STUDENTS */
export const getStudents = createSelector(
  (state) => state.students,
  (students) => students
);
export const getStudentsForm = createSelector(
  (state) => state.studentsForm,
  (form) => form
);

/* MARKS */
export const getMarks = createSelector(
  (state) => state.marks,
  (marks) => marks
);
export const getMarksForm = createSelector(
  (state) => state.marksForm,
  (form) => form
);

/* ACCESSS */
export const getAccesss = createSelector(
    (state) => state.accesss,
    (accesss) => accesss
);
export const getAccesssForm = createSelector(
    (state) => state.accesssForm,
    (form) => form
);

/* PROFESSORROLES */
export const getProfessorRoles = createSelector(
    (state) => state.professorRoles,
    (professorRoles) => professorRoles
);
export const getProfessorRolesForm = createSelector(
    (state) => state.professorRolesForm,
    (form) => form
);

/* TEACHERS */
export const getTeachers = createSelector(
    (state) => state.teachers,
    (teachers) => teachers
);
export const getTeachersForm = createSelector(
    (state) => state.teachersForm,
    (form) => form
);

/* SUBJECTTYPES */
export const getSubjectTypes = createSelector(
    (state) => state.subjectTypes,
    (subjectTypes) => subjectTypes
);
export const getSubjectTypesForm = createSelector(
    (state) => state.subjectTypesForm,
    (form) => form
);

/* PREENROLLMENTS */
export const getPreenrollments = createSelector(
    (state) => state.preenrollments,
    (preenrollments) => preenrollments
);
export const getPreenrollmentsForm = createSelector(
    (state) => state.preenrollmentsForm,
    (form) => form
);

/* LOGS */
export const getLogs = createSelector(
    (state) => state.logs,
    (logs) => logs
);
export const getLogsForm = createSelector(
    (state) => state.logsForm,
    (form) => form
);

/* CREDITTRANSFERS */
export const getCreditTransfers = createSelector(
    (state) => state.creditTransfers,
    (creditTransfers) => creditTransfers
);
export const getCreditTransfersForm = createSelector(
    (state) => state.creditTransfersForm,
    (form) => form
);

/* CREDITTRANSFERBLUEPRINTS */
export const getCreditTransferBlueprints = createSelector(
    (state) => state.creditTransferBlueprints,
    (creditTransferBlueprints) => creditTransferBlueprints
);
export const getCreditTransferBlueprintsForm = createSelector(
    (state) => state.creditTransferBlueprintsForm,
    (form) => form
);

/* CREDITCOSTS */
export const getCreditCosts = createSelector(
    (state) => state.creditCosts,
    (creditCosts) => creditCosts
);
export const getCreditCostsForm = createSelector(
    (state) => state.creditCostsForm,
    (form) => form
);

/* CREDITTRANSFERCOSTS */
export const getCreditTransferCosts = createSelector(
    (state) => state.creditTransferCosts,
    (creditTransferCosts) => creditTransferCosts
);
export const getCreditTransferCostsForm = createSelector(
    (state) => state.creditTransferCostsForm,
    (form) => form
);

/* ADMINISTRATIVECOSTS */
export const getAdministrativeCosts = createSelector(
    (state) => state.administrativeCosts,
    (administrativeCosts) => administrativeCosts
);
export const getAdministrativeCostsForm = createSelector(
    (state) => state.administrativeCostsForm,
    (form) => form
);

/* APPLICABLEDISCOUNTS */
export const getApplicablediscounts = createSelector(
    (state) => state.applicablediscounts,
    (applicablediscounts) => applicablediscounts
);
export const getApplicablediscountsForm = createSelector(
    (state) => state.applicablediscountsForm,
    (form) => form
);

/* PAYMENTPAGES */
export const getPaymentPages = createSelector(
    (state) => state.paymentPages,
    (paymentPages) => paymentPages
);
export const getPaymentPagesForm = createSelector(
    (state) => state.paymentPagesForm,
    (form) => form
);

/* CONFIGURATIONS */
export const getConfigurations = createSelector(
    (state) => state.configurations,
    (configurations) => configurations
);
export const getConfigurationsForm = createSelector(
    (state) => state.configurationsForm,
    (form) => form
);

/* PAYMENTSHEETS */
export const getPaymentSheets = createSelector(
    (state) => state.paymentSheets,
    (paymentSheets) => paymentSheets
);
export const getPaymentSheetsForm = createSelector(
    (state) => state.paymentSheetsForm,
    (form) => form
);

/* SCHOLARSHIPS */
export const getScholarships = createSelector(
    (state) => state.scholarships,
    (scholarships) => scholarships
);
export const getScholarshipsForm = createSelector(
    (state) => state.scholarshipsForm,
    (form) => form
);

/* GROUPS */
export const getGroups = createSelector(
    (state) => state.groups,
    (groups) => groups
);
export const getGroupsForm = createSelector(
    (state) => state.groupsForm,
    (form) => form
);
