import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_CREDITTRANSFERBLUEPRINT,
    DELETE_CREDITTRANSFERBLUEPRINT,
    CREDITTRANSFERBLUEPRINT_FORM_TOOGLE_LOADING,
    SET_CREDITTRANSFERBLUEPRINT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_CREDITTRANSFERBLUEPRINT,
} from "../constants";
import { formatCreditTransferBlueprints } from "./settingsActionsUtils";

/* CREDITTRANSFERBLUEPRINT LIST */
export const fetchCreditTransferBlueprints = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.creditTransferBlueprints.list)
            .then((response) => {
                const creditTransferBlueprints = formatCreditTransferBlueprints(response.data);
                dispatch({
                    type: SET_CREDITTRANSFERBLUEPRINT_LIST,
                    payload: keyBy(creditTransferBlueprints, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// CREDITTRANSFERBLUEPRINTS
export const createCreditTransferBlueprint = (newCreditTransferBlueprint) => {
    return async (dispatch) => {
        dispatch({ type: CREDITTRANSFERBLUEPRINT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.creditTransferBlueprints.create, newCreditTransferBlueprint)
            .then((response) => {
                const creditTransferBlueprint = formatCreditTransferBlueprints(response.data);
                dispatch({ type: ADD_NEW_CREDITTRANSFERBLUEPRINT, payload: creditTransferBlueprint });
                dispatch({ type: CREDITTRANSFERBLUEPRINT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Plantillas creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CREDITTRANSFERBLUEPRINT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateCreditTransferBlueprint = (updatedCreditTransferBlueprint) => {
    return async (dispatch) => {
        dispatch({ type: CREDITTRANSFERBLUEPRINT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.creditTransferBlueprints.edit}${updatedCreditTransferBlueprint && updatedCreditTransferBlueprint._id}`, updatedCreditTransferBlueprint)
            .then((response) => {
                const creditTransferBlueprint = formatCreditTransferBlueprints(response.data);
                dispatch({ type: UPDATE_CREDITTRANSFERBLUEPRINT, payload: creditTransferBlueprint });
                dispatch({ type: CREDITTRANSFERBLUEPRINT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Plantillas actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CREDITTRANSFERBLUEPRINT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteCreditTransferBlueprints = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: CREDITTRANSFERBLUEPRINT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.creditTransferBlueprints.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_CREDITTRANSFERBLUEPRINT, payload: Ids });
                dispatch({ type: CREDITTRANSFERBLUEPRINT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Plantillas eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CREDITTRANSFERBLUEPRINT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
