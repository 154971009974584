import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_CREDITTRANSFERCOST,
    DELETE_CREDITTRANSFERCOST,
    CREDITTRANSFERCOST_FORM_TOOGLE_LOADING,
    SET_CREDITTRANSFERCOST_LIST,
    SHOW_NOTIFICATION,
    UPDATE_CREDITTRANSFERCOST,
} from "../constants";
import { formatCreditTransferCosts } from "./settingsActionsUtils";

/* CREDITTRANSFERCOST LIST */
export const fetchCreditTransferCosts = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.creditTransferCosts.list)
            .then((response) => {
                const creditTransferCosts = formatCreditTransferCosts(response.data);
                dispatch({
                    type: SET_CREDITTRANSFERCOST_LIST,
                    payload: keyBy(creditTransferCosts, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// CREDITTRANSFERCOSTS
export const createCreditTransferCost = (newCreditTransferCost) => {
    return async (dispatch) => {
        dispatch({ type: CREDITTRANSFERCOST_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.creditTransferCosts.create, newCreditTransferCost)
            .then((response) => {
                const creditTransferCost = formatCreditTransferCosts(response.data);
                dispatch({ type: ADD_NEW_CREDITTRANSFERCOST, payload: creditTransferCost });
                dispatch({ type: CREDITTRANSFERCOST_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Coste de reconocimiento creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CREDITTRANSFERCOST_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateCreditTransferCost = (updatedCreditTransferCost) => {
    return async (dispatch) => {
        dispatch({ type: CREDITTRANSFERCOST_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.creditTransferCosts.edit}${updatedCreditTransferCost && updatedCreditTransferCost._id}`, updatedCreditTransferCost)
            .then((response) => {
                const creditTransferCost = formatCreditTransferCosts(response.data);
                dispatch({ type: UPDATE_CREDITTRANSFERCOST, payload: creditTransferCost });
                dispatch({ type: CREDITTRANSFERCOST_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Coste de reconocimiento actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CREDITTRANSFERCOST_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteCreditTransferCosts = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: CREDITTRANSFERCOST_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.creditTransferCosts.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_CREDITTRANSFERCOST, payload: Ids });
                dispatch({ type: CREDITTRANSFERCOST_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Coste de reconocimiento eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CREDITTRANSFERCOST_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
