import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_EDITION,
    DELETE_EDITION,
    EDITION_FORM_TOOGLE_LOADING,
    SET_EDITION_LIST,
    SHOW_NOTIFICATION,
    UPDATE_EDITION,
} from "../constants";
import { formatEditions } from "./settingsActionsUtils";

/* EDITION LIST */
export const fetchEditions = () => {
    return async (dispatch, getState) => {
        const state = getState();
        const response = await axios
            .get(API.editions.list)
            .then((response) => {
                const editions = formatEditions(response.data, state);
                dispatch({
                    type: SET_EDITION_LIST,
                    payload: keyBy(editions, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// EDITIONS
export const createEdition = (newEdition) => {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch({ type: EDITION_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.editions.create, newEdition)
            .then((response) => {
                const edition = formatEditions(response.data, state);
                dispatch({ type: ADD_NEW_EDITION, payload: edition });
                dispatch({ type: EDITION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Edición creada con éxito.",
                    },
                });
                return response;
            })
            .catch((err) => {
                dispatch({ type: EDITION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateEdition = (updatedEdition) => {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch({ type: EDITION_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.editions.edit}${updatedEdition && updatedEdition._id}`, updatedEdition)
            .then((response) => {
                const edition = formatEditions(response.data, state);
                dispatch({ type: UPDATE_EDITION, payload: edition });
                dispatch({ type: EDITION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Edición actualizada.",
                    },
                });
                return response;
            })
            .catch((err) => {
                dispatch({ type: EDITION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteEditions = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: EDITION_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.editions.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_EDITION, payload: Ids });
                dispatch({ type: EDITION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Edición eliminada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: EDITION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
