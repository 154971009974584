import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_ACCESS,
    DELETE_ACCESS,
    ACCESS_FORM_TOOGLE_LOADING,
    SET_ACCESS_LIST,
    SHOW_NOTIFICATION,
    UPDATE_ACCESS,
} from "../constants";
import { formatAccesss } from "./settingsActionsUtils";

/* ACCESS LIST */
export const fetchAccesss = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.accesss.list)
            .then((response) => {
                const accesss = formatAccesss(response.data);
                dispatch({
                    type: SET_ACCESS_LIST,
                    payload: keyBy(accesss, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// ACCESSS
export const createAccess = (newAccess) => {
    return async (dispatch) => {
        dispatch({ type: ACCESS_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.accesss.create, newAccess)
            .then((response) => {
                const access = formatAccesss(response.data);
                dispatch({ type: ADD_NEW_ACCESS, payload: access });
                dispatch({ type: ACCESS_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Acceso creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ACCESS_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateAccess = (updatedAccess) => {
    return async (dispatch) => {
        dispatch({ type: ACCESS_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.accesss.edit}${updatedAccess && updatedAccess._id}`, updatedAccess)
            .then((response) => {
                const access = formatAccesss(response.data);
                dispatch({ type: UPDATE_ACCESS, payload: access });
                dispatch({ type: ACCESS_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Acceso actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ACCESS_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteAccesss = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: ACCESS_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.accesss.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_ACCESS, payload: Ids });
                dispatch({ type: ACCESS_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Acceso eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ACCESS_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
