import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_MARK,
    DELETE_MARK,
    MARK_FORM_TOOGLE_LOADING,
    SET_MARK_LIST,
    SHOW_NOTIFICATION,
    UPDATE_MARK,
} from "../constants";
import { formatMarks } from "./settingsActionsUtils";

/* MARK LIST */
export const fetchMarks = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.marks.list)
            .then((response) => {
                const marks = formatMarks(response.data);
                dispatch({
                    type: SET_MARK_LIST,
                    payload: keyBy(marks, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// MARKS
export const createMark = (newMark) => {
    return async (dispatch) => {
        dispatch({ type: MARK_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.marks.create, newMark)
            .then((response) => {
                const mark = formatMarks(response.data);
                dispatch({ type: ADD_NEW_MARK, payload: mark });
                dispatch({ type: MARK_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Matrícula creada con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MARK_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateMark = (updatedMark) => {
    return async (dispatch) => {
        dispatch({ type: MARK_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.marks.edit}${updatedMark && updatedMark._id}`, updatedMark)
            .then((response) => {
                const mark = formatMarks(response.data);
                dispatch({ type: UPDATE_MARK, payload: mark });
                dispatch({ type: MARK_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Matrícula actualizada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MARK_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteMarks = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: MARK_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.marks.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_MARK, payload: Ids });
                dispatch({ type: MARK_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Matrícula eliminada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MARK_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
