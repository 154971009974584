import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_CREDITCOST,
    DELETE_CREDITCOST,
    CREDITCOST_FORM_TOOGLE_LOADING,
    SET_CREDITCOST_LIST,
    SHOW_NOTIFICATION,
    UPDATE_CREDITCOST,
} from "../constants";
import { formatCreditCosts } from "./settingsActionsUtils";

/* CREDITCOST LIST */
export const fetchCreditCosts = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.creditCosts.list)
            .then((response) => {
                const creditCosts = formatCreditCosts(response.data);
                dispatch({
                    type: SET_CREDITCOST_LIST,
                    payload: keyBy(creditCosts, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// CREDITCOSTS
export const createCreditCost = (newCreditCost) => {
    return async (dispatch) => {
        dispatch({ type: CREDITCOST_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.creditCosts.create, newCreditCost)
            .then((response) => {
                const creditCost = formatCreditCosts(response.data);
                dispatch({ type: ADD_NEW_CREDITCOST, payload: creditCost });
                dispatch({ type: CREDITCOST_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Coste de crédito creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CREDITCOST_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateCreditCost = (updatedCreditCost) => {
    return async (dispatch) => {
        dispatch({ type: CREDITCOST_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.creditCosts.edit}${updatedCreditCost && updatedCreditCost._id}`, updatedCreditCost)
            .then((response) => {
                const creditCost = formatCreditCosts(response.data);
                dispatch({ type: UPDATE_CREDITCOST, payload: creditCost });
                dispatch({ type: CREDITCOST_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Coste de crédito actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CREDITCOST_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteCreditCosts = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: CREDITCOST_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.creditCosts.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_CREDITCOST, payload: Ids });
                dispatch({ type: CREDITCOST_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Coste de crédito eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CREDITCOST_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
