import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_SCHOLARSHIP,
    DELETE_SCHOLARSHIP,
    SCHOLARSHIP_FORM_TOOGLE_LOADING,
    SET_SCHOLARSHIP_LIST,
    SHOW_NOTIFICATION,
    UPDATE_SCHOLARSHIP,
} from "../constants";
import { formatScholarships } from "./settingsActionsUtils";

/* SCHOLARSHIP LIST */
export const fetchScholarships = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.scholarships.list)
            .then((response) => {
                const scholarships = formatScholarships(response.data);
                dispatch({
                    type: SET_SCHOLARSHIP_LIST,
                    payload: keyBy(scholarships, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// SCHOLARSHIPS
export const createScholarship = (newScholarship) => {
    return async (dispatch) => {
        dispatch({ type: SCHOLARSHIP_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.scholarships.create, newScholarship)
            .then((response) => {
                const scholarship = formatScholarships(response.data);
                dispatch({ type: ADD_NEW_SCHOLARSHIP, payload: scholarship });
                dispatch({ type: SCHOLARSHIP_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Beca creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: SCHOLARSHIP_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateScholarship = (updatedScholarship) => {
    return async (dispatch) => {
        dispatch({ type: SCHOLARSHIP_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.scholarships.edit}${updatedScholarship && updatedScholarship._id}`, updatedScholarship)
            .then((response) => {
                const scholarship = formatScholarships(response.data);
                dispatch({ type: UPDATE_SCHOLARSHIP, payload: scholarship });
                dispatch({ type: SCHOLARSHIP_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Beca actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: SCHOLARSHIP_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteScholarships = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: SCHOLARSHIP_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.scholarships.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_SCHOLARSHIP, payload: Ids });
                dispatch({ type: SCHOLARSHIP_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Beca eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: SCHOLARSHIP_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
