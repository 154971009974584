import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_CLASSROOM,
    DELETE_CLASSROOM,
    CLASSROOM_FORM_TOOGLE_LOADING,
    SET_CLASSROOM_LIST,
    SHOW_NOTIFICATION,
    UPDATE_CLASSROOM,
} from "../constants";
import { formatClassrooms } from "./settingsActionsUtils";

/* CLASSROOM LIST */
export const fetchClassrooms = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.classrooms.list)
            .then((response) => {
                const classrooms = formatClassrooms(response.data);
                dispatch({
                    type: SET_CLASSROOM_LIST,
                    payload: keyBy(classrooms, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// CLASSROOMS
export const createClassroom = (newClassroom) => {
    return async (dispatch) => {
        dispatch({ type: CLASSROOM_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.classrooms.create, newClassroom)
            .then((response) => {
                const classroom = formatClassrooms(response.data);
                dispatch({ type: ADD_NEW_CLASSROOM, payload: classroom });
                dispatch({ type: CLASSROOM_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Aula creada con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CLASSROOM_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateClassroom = (updatedClassroom) => {
    return async (dispatch) => {
        dispatch({ type: CLASSROOM_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.classrooms.edit}${updatedClassroom && updatedClassroom._id}`, updatedClassroom)
            .then((response) => {
                const classroom = formatClassrooms(response.data);
                dispatch({ type: UPDATE_CLASSROOM, payload: classroom });
                dispatch({ type: CLASSROOM_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Aula actualizada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CLASSROOM_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteClassrooms = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: CLASSROOM_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.classrooms.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_CLASSROOM, payload: Ids });
                dispatch({ type: CLASSROOM_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Aula eliminada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CLASSROOM_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
