import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_ADMINISTRATIVECOST,
    DELETE_ADMINISTRATIVECOST,
    ADMINISTRATIVECOST_FORM_TOOGLE_LOADING,
    SET_ADMINISTRATIVECOST_LIST,
    SHOW_NOTIFICATION,
    UPDATE_ADMINISTRATIVECOST,
} from "../constants";
import { formatAdministrativeCosts } from "./settingsActionsUtils";

/* ADMINISTRATIVECOST LIST */
export const fetchAdministrativeCosts = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.administrativeCosts.list)
            .then((response) => {
                const administrativeCosts = formatAdministrativeCosts(response.data);
                dispatch({
                    type: SET_ADMINISTRATIVECOST_LIST,
                    payload: keyBy(administrativeCosts, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// ADMINISTRATIVECOSTS
export const createAdministrativeCost = (newAdministrativeCost) => {
    return async (dispatch) => {
        dispatch({ type: ADMINISTRATIVECOST_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.administrativeCosts.create, newAdministrativeCost)
            .then((response) => {
                const administrativeCost = formatAdministrativeCosts(response.data);
                dispatch({ type: ADD_NEW_ADMINISTRATIVECOST, payload: administrativeCost });
                dispatch({ type: ADMINISTRATIVECOST_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Coste administrativo creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ADMINISTRATIVECOST_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateAdministrativeCost = (updatedAdministrativeCost) => {
    return async (dispatch) => {
        dispatch({ type: ADMINISTRATIVECOST_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.administrativeCosts.edit}${updatedAdministrativeCost && updatedAdministrativeCost._id}`, updatedAdministrativeCost)
            .then((response) => {
                const administrativeCost = formatAdministrativeCosts(response.data);
                dispatch({ type: UPDATE_ADMINISTRATIVECOST, payload: administrativeCost });
                dispatch({ type: ADMINISTRATIVECOST_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Coste administrativo actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ADMINISTRATIVECOST_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteAdministrativeCosts = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: ADMINISTRATIVECOST_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.administrativeCosts.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_ADMINISTRATIVECOST, payload: Ids });
                dispatch({ type: ADMINISTRATIVECOST_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Coste administrativo eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ADMINISTRATIVECOST_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
