import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_STUDENT,
    DELETE_STUDENT,
    STUDENT_FORM_TOOGLE_LOADING,
    SET_STUDENT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_STUDENT,
} from "../constants";
import { formatStudents } from "./settingsActionsUtils";

/* STUDENT LIST */
export const fetchStudents = () => {
    return async (dispatch, useState) => {
        const response = await axios
            .get(API.students.list)
            .then((response) => {
                const students = formatStudents(response.data, useState());
                dispatch({
                    type: SET_STUDENT_LIST,
                    payload: keyBy(students, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// STUDENTS
export const createStudent = (newStudent) => {
    return async (dispatch, useState) => {
        dispatch({ type: STUDENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.students.create, newStudent)
            .then((response) => {
                const student = formatStudents(response.data, useState());
                dispatch({ type: ADD_NEW_STUDENT, payload: student });
                dispatch({ type: STUDENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Alumno creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: STUDENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateStudent = (updatedStudent) => {
    return async (dispatch, useState) => {
        dispatch({ type: STUDENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.students.edit}${updatedStudent && updatedStudent._id}`, updatedStudent)
            .then((response) => {
                const student = formatStudents(response.data, useState());
                dispatch({ type: UPDATE_STUDENT, payload: student });
                dispatch({ type: STUDENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Alumno actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: STUDENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteStudents = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: STUDENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.students.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_STUDENT, payload: Ids });
                dispatch({ type: STUDENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Alumno eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: STUDENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
