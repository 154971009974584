import { GroupOutlined  } from "@ant-design/icons";
import { FileDoneOutlined } from "@ant-design/icons";
import { EuroCircleOutlined, SettingOutlined } from "@ant-design/icons";
import { FileTextOutlined } from "@ant-design/icons";
import { FileProtectOutlined } from "@ant-design/icons";
import { FormOutlined } from "@ant-design/icons";
import { SafetyOutlined } from "@ant-design/icons";
import { CheckSquareOutlined } from "@ant-design/icons";
import { AuditOutlined, BankOutlined, BarsOutlined, BookOutlined, CalendarOutlined, CarryOutOutlined, DeploymentUnitOutlined, HomeOutlined, InteractionOutlined, LoginOutlined, LogoutOutlined, ProfileOutlined, ReadOutlined, ReconciliationOutlined, UserOutlined } from '@ant-design/icons';
import React from 'react';
import { ROLES } from "./permissions";

export const routes = {
  home: {
    key: "home",
    slug: "home",
    to: "/home",
    title: "Inicio",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.teacher.role],
  },

  profile: {
    key: "profile",
    slug: "profile",
    to: "/profile",
    title: "Perfil",
    icon: <UserOutlined />,
    showInMenu: false,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

  users: {
    key: "users",
    slug: "users",
    to: "/users",
    title: "Usuarios",
    icon: <UserOutlined />,
    showInMenu: false,
    showHeader: false,
    showSearch: false,
    action: null,
    role: [],
  },

  login: {
    key: "login",
    slug: "login",
    to: "/login",
    title: "LOGIN",
    icon: <LoginOutlined />,
    showInMenu: false,
    showHeader: false,
    showSearch: false,
    action: null,
    role: [ROLES.admin.role],
  },
  bookings: {
    key: "bookings",
    slug: "bookings",
    to: "/bookings",
    title: "Reservas",
    icon: <CarryOutOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  calendarBookings: {
    key: "calendarBookings",
    slug: "calendarBookings",
    to: "/calendarBookings",
    title: "Calendario Reservas",
    icon: <CalendarOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  calendarClassrooms: {
    key: "calendarClassrooms",
    slug: "calendarClassrooms",
    to: "/calendarClassrooms",
    title: "Calendario Aulas",
    icon: <CalendarOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  classrooms: {
    key: "classrooms",
    slug: "classrooms",
    to: "/classrooms",
    title: "Aulas",
    icon: <BankOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  studies: {
    key: "studies",
    slug: "studies",
    to: "/studies",
    title: "Estudios",
    icon: <ReconciliationOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  courses: {
    key: "courses",
    slug: "courses",
    to: "/courses",
    title: "Cursos",
    icon: <BookOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  subjects: {
    key: "subjects",
    slug: "subjects",
    to: "/subjects",
    title: "Asignaturas",
    icon: <ReadOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  grades: {
    key: "grades",
    slug: "grades",
    to: "/grades",
    title: "Tipos de formación",
    icon: <AuditOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    parent: "masters",
    role: [ROLES.admin.role],
  },
  resources: {
    key: "resources",
    slug: "resources",
    to: "/resources",
    title: "Recursos",
    icon: <BarsOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    parent: "masters",
    role: [ROLES.admin.role],
  },
  booktypes: {
    key: "booktypes",
    slug: "booktypes",
    to: "/booktypes",
    title: "Tipo de Reservas",
    icon: < InteractionOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    parent: "masters",
    role: [ROLES.admin.role],
  },
  editions: {
    key: "editions",
    slug: "editions",
    to: "/editions",
    title: "Ediciones",
    icon: <ProfileOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  editionxsubjects: {
    key: "editionxsubjects",
    slug: "editionxsubjects",
    to: "/editionxsubjects",
    title: "Edición x Asig",
    icon: <DeploymentUnitOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  students: {
    key: "students",
    slug: "students",
    to: "/students",
    title: "Alumnos",
    icon: <UserOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    parent: "rrhh",
    role: [ROLES.admin.role],
  },
  teachers: {
    key: "teachers",
    slug: "teachers",
    to: "/teachers",
    title: "Profesores",
    icon: <UserOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    parent: "rrhh",
    role: [ROLES.admin.role],
  },
  enrollments: {
    key: "enrollments",
    slug: "enrollments",
    to: "/enrollments",
    title: "Matrículas",
    icon: <BookOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  preenrollments: {
    key: "preenrollments",
    slug: "preenrollments",
    to: "/preenrollments",
    title: "Prematrículas",
    icon: <FormOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
    parent: "enrollments"
  },
  marks: {
    key: "marks",
    slug: "marks",
    to: "/marks",
    title: "Matrículas",
    icon: <BookOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.teacher.role],
    parent: "enrollments"
  },
  accesss: {
    key: "accesss",
    slug: "accesss",
    to: "/accesss",
    title: "Accesos",
    icon: <CheckSquareOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    parent: "masters",
    role: [ROLES.admin.role],
  },
  professorRoles: {
    key: "professorRoles",
    slug: "professorRoles",
    to: "/professorRoles",
    title: "Roles profesores",
    icon: <SafetyOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    parent: "masters",
    role: [ROLES.admin.role],
  },
  rrhh: {
    key: "rrhh",
    slug: "rrhh",
    to: "/rrhh",
    title: "RRHH",
    icon: <UserOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  masters: {
    key: "masters",
    slug: "masters",
    to: "/masters",
    title: "Maestros",
    icon: <BookOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  subjectTypes: {
    key: "subjectTypes",
    slug: "subjectTypes",
    to: "/subjectTypes",
    title: "Tipo de asignaturas",
    icon: <BookOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
    parent: "masters",
  },
  logs: {
    key: "logs",
    slug: "logs",
    to: "/logs",
    title: "Logs",
    icon: <FileProtectOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  creditTransfers: {
    key: "creditTransfers",
    slug: "creditTransfers",
    to: "/creditTransfers",
    title: "Reconocimientos de créditos",
    icon: <FileTextOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
    parent: "creditTransfer"
  },
  creditTransfer: {
    key: "creditTransfer",
    slug: "creditTransfer",
    to: "/creditTransfer",
    title: "Reconocimiento de créditos",
    icon: <FileTextOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  creditTransferBlueprints: {
    key: "creditTransferBlueprints",
    slug: "creditTransferBlueprints",
    to: "/creditTransferBlueprints",
    title: "Plantillas",
    icon: <ProfileOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
    parent: "creditTransfer"
  },
  costs: {
    key: "costs",
    slug: "costs",
    to: "/costs",
    title: "Costes",
    icon: <EuroCircleOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  creditCosts: {
    key: "creditCosts",
    slug: "creditCosts",
    to: "/creditCosts",
    title: "Coste de créditos",
    icon: <EuroCircleOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
    parent: "costs"
  },
  creditTransferCosts: {
    key: "creditTransferCosts",
    slug: "creditTransferCosts",
    to: "/creditTransferCosts",
    title: "Coste de reconocimientos",
    icon: <FileTextOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
    parent: "costs"
  },
  administrativeCosts: {
    key: "administrativeCosts",
    slug: "administrativeCosts",
    to: "/administrativeCosts",
    title: "Coste administrativos",
    icon: <FileTextOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
    parent: "costs"
  },
  applicablediscounts: {
    key: "applicablediscounts",
    slug: "applicablediscounts",
    to: "/applicablediscounts",
    title: "Descuento aplicables",
    icon: <FileTextOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
    parent: "costs"
  },
  paymentPages: {
    key: "paymentPages",
    slug: "paymentPages",
    to: "/paymentPages",
    title: "Hoja de pagos",
    icon: <FileTextOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
    parent: "costs"
  },
  scholarships: {
    key: "scholarships",
    slug: "scholarships",
    to: "/scholarships",
    title: "Becas",
    icon: <FileDoneOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  configurations: {
    key: "configurations",
    slug: "configurations",
    to: "/configurations",
    title: "Configuración",
    icon: <SettingOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
groups: {
    key: "groups",
    slug: "groups",
    to: "/groups",
    title: "Grupos",
    icon: <GroupOutlined  />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
},
  logout: {
    key: "logout",
    slug: "logout",
    to: "/logout",
    title: "Cerrar Sesión",
    icon: <LogoutOutlined />,
    showInMenu: false,
    showHeader: false,
    showSearch: false,
    action: null,
    role: [ROLES.admin.role],
  },
};
