import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_SUBJECT,
    DELETE_SUBJECT,
    SUBJECT_FORM_TOOGLE_LOADING,
    SET_SUBJECT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_SUBJECT,
} from "../constants";
import { formatSubjects } from "./settingsActionsUtils";

/* SUBJECT LIST */
export const fetchSubjects = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.subjects.list)
            .then((response) => {
                const subjects = formatSubjects(response.data);
                dispatch({
                    type: SET_SUBJECT_LIST,
                    payload: keyBy(subjects, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// SUBJECTS
export const createSubject = (newSubject) => {
    return async (dispatch) => {
        dispatch({ type: SUBJECT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.subjects.create, newSubject)
            .then((response) => {
                const subject = formatSubjects(response.data);
                dispatch({ type: ADD_NEW_SUBJECT, payload: subject });
                dispatch({ type: SUBJECT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Asignatura creada con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: SUBJECT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateSubject = (updatedSubject) => {
    return async (dispatch) => {
        dispatch({ type: SUBJECT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.subjects.edit}${updatedSubject && updatedSubject._id}`, updatedSubject)
            .then((response) => {
                const subject = formatSubjects(response.data);
                dispatch({ type: UPDATE_SUBJECT, payload: subject });
                dispatch({ type: SUBJECT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Asignatura actualizada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: SUBJECT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteSubjects = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: SUBJECT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.subjects.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_SUBJECT, payload: Ids });
                dispatch({ type: SUBJECT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Asignatura eliminada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: SUBJECT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
