import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_PAYMENTPAGE,
    DELETE_PAYMENTPAGE,
    PAYMENTPAGE_FORM_TOOGLE_LOADING,
    SET_PAYMENTPAGE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_PAYMENTPAGE,
} from "../constants";
import { formatPaymentPages } from "./settingsActionsUtils";

/* PAYMENTPAGE LIST */
export const fetchPaymentPages = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.paymentPages.list)
            .then((response) => {
                const paymentPages = formatPaymentPages(response.data);
                dispatch({
                    type: SET_PAYMENTPAGE_LIST,
                    payload: keyBy(paymentPages, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// PAYMENTPAGES
export const createPaymentPage = (newPaymentPage) => {
    return async (dispatch) => {
        dispatch({ type: PAYMENTPAGE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.paymentPages.create, newPaymentPage)
            .then((response) => {
                const paymentPage = formatPaymentPages(response.data);
                dispatch({ type: ADD_NEW_PAYMENTPAGE, payload: paymentPage });
                dispatch({ type: PAYMENTPAGE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Hoja de pago creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PAYMENTPAGE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updatePaymentPage = (updatedPaymentPage) => {
    return async (dispatch) => {
        dispatch({ type: PAYMENTPAGE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.paymentPages.edit}${updatedPaymentPage && updatedPaymentPage._id}`, updatedPaymentPage)
            .then((response) => {
                const paymentPage = formatPaymentPages(response.data);
                dispatch({ type: UPDATE_PAYMENTPAGE, payload: paymentPage });
                dispatch({ type: PAYMENTPAGE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Hoja de pago actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PAYMENTPAGE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deletePaymentPages = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: PAYMENTPAGE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.paymentPages.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_PAYMENTPAGE, payload: Ids });
                dispatch({ type: PAYMENTPAGE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Hoja de pago eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PAYMENTPAGE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
