import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_SUBJECTTYPE,
    DELETE_SUBJECTTYPE,
    SUBJECTTYPE_FORM_TOOGLE_LOADING,
    SET_SUBJECTTYPE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_SUBJECTTYPE,
} from "../constants";
import { formatSubjectTypes } from "./settingsActionsUtils";

/* SUBJECTTYPE LIST */
export const fetchSubjectTypes = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.subjectTypes.list)
            .then((response) => {
                const subjectTypes = formatSubjectTypes(response.data);
                dispatch({
                    type: SET_SUBJECTTYPE_LIST,
                    payload: keyBy(subjectTypes, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// SUBJECTTYPES
export const createSubjectType = (newSubjectType) => {
    return async (dispatch) => {
        dispatch({ type: SUBJECTTYPE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.subjectTypes.create, newSubjectType)
            .then((response) => {
                const subjectType = formatSubjectTypes(response.data);
                dispatch({ type: ADD_NEW_SUBJECTTYPE, payload: subjectType });
                dispatch({ type: SUBJECTTYPE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Tipo de asignatura creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: SUBJECTTYPE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateSubjectType = (updatedSubjectType) => {
    return async (dispatch) => {
        dispatch({ type: SUBJECTTYPE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.subjectTypes.edit}${updatedSubjectType && updatedSubjectType._id}`, updatedSubjectType)
            .then((response) => {
                const subjectType = formatSubjectTypes(response.data);
                dispatch({ type: UPDATE_SUBJECTTYPE, payload: subjectType });
                dispatch({ type: SUBJECTTYPE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Tipo de asignatura actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: SUBJECTTYPE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteSubjectTypes = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: SUBJECTTYPE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.subjectTypes.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_SUBJECTTYPE, payload: Ids });
                dispatch({ type: SUBJECTTYPE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Tipo de asignatura eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: SUBJECTTYPE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
