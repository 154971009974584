import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_PAYMENTSHEET,
    DELETE_PAYMENTSHEET,
    PAYMENTSHEET_FORM_TOOGLE_LOADING,
    SET_PAYMENTSHEET_LIST,
    SHOW_NOTIFICATION,
    UPDATE_PAYMENTSHEET,
} from "../constants";
import { formatPaymentSheets } from "./settingsActionsUtils";

/* PAYMENTSHEET LIST */
export const fetchPaymentSheets = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.paymentSheets.list)
            .then((response) => {
                const paymentSheets = formatPaymentSheets(response.data);
                dispatch({
                    type: SET_PAYMENTSHEET_LIST,
                    payload: keyBy(paymentSheets, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// PAYMENTSHEETS
export const createPaymentSheet = (newPaymentSheet) => {
    return async (dispatch) => {
        dispatch({ type: PAYMENTSHEET_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.paymentSheets.create, newPaymentSheet)
            .then((response) => {
                const paymentSheet = formatPaymentSheets(response.data);
                dispatch({ type: ADD_NEW_PAYMENTSHEET, payload: paymentSheet });
                dispatch({ type: PAYMENTSHEET_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Hoja de pago creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PAYMENTSHEET_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updatePaymentSheet = (updatedPaymentSheet) => {
    return async (dispatch) => {
        dispatch({ type: PAYMENTSHEET_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.paymentSheets.edit}${updatedPaymentSheet && updatedPaymentSheet._id}`, updatedPaymentSheet)
            .then((response) => {
                const paymentSheet = formatPaymentSheets(response.data);
                dispatch({ type: UPDATE_PAYMENTSHEET, payload: paymentSheet });
                dispatch({ type: PAYMENTSHEET_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Hoja de pago actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PAYMENTSHEET_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deletePaymentSheets = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: PAYMENTSHEET_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.paymentSheets.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_PAYMENTSHEET, payload: Ids });
                dispatch({ type: PAYMENTSHEET_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Hoja de pago eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PAYMENTSHEET_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
