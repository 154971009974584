import { axios } from "../connection/ConnectionHandler";
import { fetchGroups } from "./groupsActions";
import { fetchScholarships } from "./scholarshipsActions";
import { fetchPaymentSheets } from "./paymentSheetsActions";
import { fetchConfigurations } from "./configurationsActions";
import { fetchPaymentPages } from "./paymentPagesActions";
import { fetchApplicablediscounts } from "./applicablediscountsActions";
import { fetchAdministrativeCosts } from "./administrativeCostsActions";
import { fetchCreditTransferCosts } from "./creditTransferCostsActions";
import { fetchCreditCosts } from "./creditCostsActions";
import { fetchCreditTransferBlueprints } from "./creditTransferBlueprintsActions";
import { fetchCreditTransfers } from "./creditTransfersActions";
import { fetchLogs } from "./logsActions";
import { fetchPreenrollments } from "./preenrollmentsActions";
import { fetchSubjectTypes } from "./subjectTypesActions";
import { fetchTeachers } from "./teachersActions";
import { fetchProfessorRoles } from "./professorRolesActions";
import { fetchAccesss } from "./accesssActions";
import { fetchMarks } from "./marksActions";
import { fetchStudents } from "./studentsActions";
import { fetchEditionxsubjects } from "./editionxsubjectsActions";
import { fetchEditions } from "./editionsActions";
import { fetchBooktypes } from "./booktypesActions";
import { fetchStudies } from "./studiesActions";
import { fetchResources } from "./resourcesActions";
import { fetchGrades } from "./gradesActions";
import { fetchBookings } from "./bookingsActions";
import { fetchSubjects } from "./subjectsActions";
import { fetchClassrooms } from "./classroomsActions";
import { fetchCourses } from "./coursesActions";
import {
  APP_COOKIE_NAME,
  AUTH,
  PASS_COOKIE_NAME,
  SET_CURRENT_ROUTE,
  SET_PROFILE,
  USER_COOKIE_NAME,
  USER_ID_COOKIE_NAME,
} from "../constants";
import { API } from "../constants/api";
import { deleteCookie, getCookie, setCookie } from "../utils";
import { fetchUsers } from "./usersActions";
import { routes } from "../constants/routes";

const rememberLogin = (username, password, remember) => {
  if (remember) {
    setCookie(USER_COOKIE_NAME, username, 30);
    setCookie(PASS_COOKIE_NAME, password, 30);
  } else {
    deleteCookie(USER_COOKIE_NAME, "");
    deleteCookie(PASS_COOKIE_NAME, "");
  }
};

/* PERSISTENCY CHECK */
export const checkToken = () => {
  return async (dispatch) => {
    const token = await getCookie(APP_COOKIE_NAME);
    const userId = await getCookie(USER_ID_COOKIE_NAME);
    if (token) {
      dispatch({ type: AUTH, payload: { auth: true, token, userId } });
    }
  };
};

export const fetchUserProfile = () => {
  return (dispatch) => {
    axios
      .get(API.users.profile)
      .then((response) =>
        dispatch({ type: SET_PROFILE, payload: response.data })
      )
      .catch((err) => err);
  };
};

/* INITAL DATA LOAD */
export const loadInitialData = () => {
  return async (dispatch) => {
    await dispatch(fetchUsers());
		dispatch(fetchGroups());
		dispatch(fetchScholarships());
		dispatch(fetchPaymentSheets());
		dispatch(fetchConfigurations());
		dispatch(fetchPaymentPages());
		dispatch(fetchApplicablediscounts());
		dispatch(fetchAdministrativeCosts());
		dispatch(fetchCreditTransferCosts());
		dispatch(fetchCreditCosts());
		dispatch(fetchCreditTransferBlueprints());
		dispatch(fetchCreditTransfers());
		dispatch(fetchLogs());
		dispatch(fetchPreenrollments());
		dispatch(fetchSubjectTypes());
		dispatch(fetchTeachers());
		dispatch(fetchProfessorRoles());
    await dispatch(fetchStudies());
    await dispatch(fetchAccesss());
    await dispatch(fetchMarks());
    await dispatch(fetchBooktypes());
    await dispatch(fetchResources());
    await dispatch(fetchGrades());
    await dispatch(fetchBookings());
    await dispatch(fetchSubjects());
    await dispatch(fetchClassrooms());
    await dispatch(fetchCourses());
    await dispatch(fetchEditions());
    await dispatch(fetchEditionxsubjects());
    await dispatch(fetchStudents());
  };
};

export const login = (username, password, remember) => {
  return async (dispatch) => {
    const response = await axios
      .post(API.auth.login, { username, password })
      .then((response) => {
        if (response && response.data) {
          const { access_token } = response.data || {};
          rememberLogin(username, password, remember);
          dispatch({
            type: AUTH,
            payload: { auth: true, token: access_token },
          });
          dispatch({ type: SET_PROFILE, payload: response.data.resUser });
          dispatch({
            type: SET_CURRENT_ROUTE,
            payload: routes["home"],
          });
        }
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const check2FA = (data) => {
  return async (dispatch) => {
    const response = await axios
      .post(API.auth.check2FA, data)
      .then((response) => {
        if (response && response.data) {
          const { access_token } = response.data || {};
          rememberLogin(data.username, data.password, data.remember);
          dispatch({
            type: AUTH,
            payload: { auth: true, token: access_token },
          });
          dispatch({ type: SET_PROFILE, payload: response.data.resUser });
          dispatch({
            type: SET_CURRENT_ROUTE,
            payload: routes["home"],
          });
        }
        return response;
      })
      .catch((err) => err);
    return response;
  };
};
