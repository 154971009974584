import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_PREENROLLMENT,
    DELETE_PREENROLLMENT,
    PREENROLLMENT_FORM_TOOGLE_LOADING,
    SET_PREENROLLMENT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_PREENROLLMENT,
} from "../constants";
import { formatPreenrollments } from "./settingsActionsUtils";

/* PREENROLLMENT LIST */
export const fetchPreenrollments = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.preenrollments.list)
            .then((response) => {
                const preenrollments = formatPreenrollments(response.data);
                dispatch({
                    type: SET_PREENROLLMENT_LIST,
                    payload: keyBy(preenrollments, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// PREENROLLMENTS
export const createPreenrollment = (newPreenrollment) => {
    return async (dispatch) => {
        dispatch({ type: PREENROLLMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.preenrollments.create, newPreenrollment)
            .then((response) => {
                const preenrollment = formatPreenrollments(response.data);
                dispatch({ type: ADD_NEW_PREENROLLMENT, payload: preenrollment });
                dispatch({ type: PREENROLLMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Prematrícula creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PREENROLLMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updatePreenrollment = (updatedPreenrollment) => {
    return async (dispatch) => {
        dispatch({ type: PREENROLLMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.preenrollments.edit}${updatedPreenrollment && updatedPreenrollment._id}`, updatedPreenrollment)
            .then((response) => {
                const preenrollment = formatPreenrollments(response.data);
                dispatch({ type: UPDATE_PREENROLLMENT, payload: preenrollment });
                dispatch({ type: PREENROLLMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Prematrícula actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PREENROLLMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deletePreenrollments = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: PREENROLLMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.preenrollments.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_PREENROLLMENT, payload: Ids });
                dispatch({ type: PREENROLLMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Prematrícula eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PREENROLLMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
