import { keyBy } from "lodash";
import { axios } from "../connection/ConnectionHandler";
import {
    ADD_NEW_TEACHER,
    DELETE_TEACHER,
    SET_TEACHER_LIST,
    SHOW_NOTIFICATION,
    TEACHER_FORM_TOOGLE_LOADING,
    UPDATE_TEACHER,
} from "../constants";
import { API } from "../constants/api";
import { formatTeachers } from "./settingsActionsUtils";

/* TEACHER LIST */
export const fetchTeachers = () => {
    return async (dispatch, useState) => {
        const response = await axios
            .get(API.teachers.list)
            .then((response) => {
                const teachers = formatTeachers(response.data, useState());
                dispatch({
                    type: SET_TEACHER_LIST,
                    payload: keyBy(teachers, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// TEACHERS
export const createTeacher = (newTeacher) => {
    return async (dispatch, useState) => {
        dispatch({ type: TEACHER_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.teachers.create, newTeacher)
            .then((response) => {
                const teacher = formatTeachers(response.data, useState());
                dispatch({ type: ADD_NEW_TEACHER, payload: teacher });
                dispatch({ type: TEACHER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Profesor creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: TEACHER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateTeacher = (updatedTeacher) => {
    return async (dispatch, useState) => {
        dispatch({ type: TEACHER_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.teachers.edit}${updatedTeacher && updatedTeacher._id}`, updatedTeacher)
            .then((response) => {
                const teacher = formatTeachers(response.data, useState());
                dispatch({ type: UPDATE_TEACHER, payload: teacher });
                dispatch({ type: TEACHER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Profesor actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: TEACHER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteTeachers = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: TEACHER_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.teachers.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_TEACHER, payload: Ids });
                dispatch({ type: TEACHER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Profesor eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: TEACHER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
