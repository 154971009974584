import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_EDITIONXSUBJECT,
    DELETE_EDITIONXSUBJECT,
    EDITIONXSUBJECT_FORM_TOOGLE_LOADING,
    SET_EDITIONXSUBJECT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_EDITIONXSUBJECT,
} from "../constants";
import { formatEditionxsubjects } from "./settingsActionsUtils";

/* EDITIONXSUBJECT LIST */
export const fetchEditionxsubjects = () => {
    return async (dispatch, getState) => {
        const response = await axios
            .get(API.editionxsubjects.list)
            .then((response) => {
                const editionxsubjects = formatEditionxsubjects(response.data, getState);
                dispatch({
                    type: SET_EDITIONXSUBJECT_LIST,
                    payload: keyBy(editionxsubjects, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// EDITIONXSUBJECTS
export const createEditionxsubject = (newEditionxsubject) => {
    return async (dispatch, getState) => {
        dispatch({ type: EDITIONXSUBJECT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.editionxsubjects.create, newEditionxsubject)
            .then((response) => {
                const editionxsubject = formatEditionxsubjects(response.data, getState);
                dispatch({ type: ADD_NEW_EDITIONXSUBJECT, payload: editionxsubject });
                dispatch({ type: EDITIONXSUBJECT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Edición x Asignatura creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: EDITIONXSUBJECT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateEditionxsubject = (updatedEditionxsubject) => {
    return async (dispatch, getState) => {
        dispatch({ type: EDITIONXSUBJECT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.editionxsubjects.edit}${updatedEditionxsubject && updatedEditionxsubject._id}`, updatedEditionxsubject)
            .then((response) => {
                const editionxsubject = formatEditionxsubjects(response.data, getState);
                dispatch({ type: UPDATE_EDITIONXSUBJECT, payload: editionxsubject });
                dispatch({ type: EDITIONXSUBJECT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Edición x Asignatura actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: EDITIONXSUBJECT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteEditionxsubjects = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: EDITIONXSUBJECT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.editionxsubjects.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_EDITIONXSUBJECT, payload: Ids });
                dispatch({ type: EDITIONXSUBJECT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Edición x Asignatura eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: EDITIONXSUBJECT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
