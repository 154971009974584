/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
export const SET_USER_LIST = "SET_USER_LIST";
export const EDIT_DISABLED_BUTTONS = "EDIT_DISABLED_BUTTONS";
export const SET_LOGS_LIST = "SET_LOGS_LIST";
export const THEME = "THEME";

/* <---- BASIC APP CONSTANTS ----> */

/* SOCKET CONSTANTS */
export const CONNECTED = "connected";
export const DISCONNECTED = "disconnected";
/* <---- SOCKET CONSTANTS ----> */

/* CONFIGURATION */
export const CONFIGURATION_FORM_TOOGLE_LOADING =
  "CONFIGURATION_FORM_TOOGLE_LOADING";
export const SET_CONFIGURATION = "SET_CONFIGURATION";
/* <---- CONFIGURATION ----> */

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const OPEN_USERS_FORM = "OPEN_USERS_FORM";
export const CLOSE_USERS_FORM = "CLOSE_USERS_FORM";
export const EDIT_SELECTED_USER = "EDIT_SELECTED_USER";
export const USERS_FORM_TOOGLE_LOADING = "USERS_FORM_TOOGLE_LOADING";
/* <---- USERS ----> */

/* GROUP */
export const SET_GROUP_LIST = "SET_GROUP_LIST";
export const ADD_NEW_GROUP = "ADD_NEW_GROUP";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const DELETE_GROUP = "DELETE_GROUP";
export const OPEN_GROUP_FORM = "OPEN_GROUP_FORM";
export const CLOSE_GROUP_FORM = "CLOSE_GROUP_FORM";
export const EDIT_SELECTED_GROUP = "EDIT_SELECTED_GROUP";
export const GROUP_FORM_TOOGLE_LOADING = "GROUP_FORM_TOOGLE_LOADING";
/* <---- GROUP ----> */

/* SCHOLARSHIP */
export const SET_SCHOLARSHIP_LIST = "SET_SCHOLARSHIP_LIST";
export const ADD_NEW_SCHOLARSHIP = "ADD_NEW_SCHOLARSHIP";
export const UPDATE_SCHOLARSHIP = "UPDATE_SCHOLARSHIP";
export const DELETE_SCHOLARSHIP = "DELETE_SCHOLARSHIP";
export const OPEN_SCHOLARSHIP_FORM = "OPEN_SCHOLARSHIP_FORM";
export const CLOSE_SCHOLARSHIP_FORM = "CLOSE_SCHOLARSHIP_FORM";
export const EDIT_SELECTED_SCHOLARSHIP = "EDIT_SELECTED_SCHOLARSHIP";
export const SCHOLARSHIP_FORM_TOOGLE_LOADING = "SCHOLARSHIP_FORM_TOOGLE_LOADING";
/* <---- SCHOLARSHIP ----> */

/* PAYMENTSHEET */
export const SET_PAYMENTSHEET_LIST = "SET_PAYMENTSHEET_LIST";
export const ADD_NEW_PAYMENTSHEET = "ADD_NEW_PAYMENTSHEET";
export const UPDATE_PAYMENTSHEET = "UPDATE_PAYMENTSHEET";
export const DELETE_PAYMENTSHEET = "DELETE_PAYMENTSHEET";
export const OPEN_PAYMENTSHEET_FORM = "OPEN_PAYMENTSHEET_FORM";
export const CLOSE_PAYMENTSHEET_FORM = "CLOSE_PAYMENTSHEET_FORM";
export const EDIT_SELECTED_PAYMENTSHEET = "EDIT_SELECTED_PAYMENTSHEET";
export const PAYMENTSHEET_FORM_TOOGLE_LOADING = "PAYMENTSHEET_FORM_TOOGLE_LOADING";
/* <---- PAYMENTSHEET ----> */

/* CONFIGURATION */
export const SET_CONFIGURATION_LIST = "SET_CONFIGURATION_LIST";
export const ADD_NEW_CONFIGURATION = "ADD_NEW_CONFIGURATION";
export const UPDATE_CONFIGURATION = "UPDATE_CONFIGURATION";
export const DELETE_CONFIGURATION = "DELETE_CONFIGURATION";
export const OPEN_CONFIGURATION_FORM = "OPEN_CONFIGURATION_FORM";
export const CLOSE_CONFIGURATION_FORM = "CLOSE_CONFIGURATION_FORM";
export const EDIT_SELECTED_CONFIGURATION = "EDIT_SELECTED_CONFIGURATION";
/* <---- CONFIGURATION ----> */

/* PAYMENTPAGE */
export const SET_PAYMENTPAGE_LIST = "SET_PAYMENTPAGE_LIST";
export const ADD_NEW_PAYMENTPAGE = "ADD_NEW_PAYMENTPAGE";
export const UPDATE_PAYMENTPAGE = "UPDATE_PAYMENTPAGE";
export const DELETE_PAYMENTPAGE = "DELETE_PAYMENTPAGE";
export const OPEN_PAYMENTPAGE_FORM = "OPEN_PAYMENTPAGE_FORM";
export const CLOSE_PAYMENTPAGE_FORM = "CLOSE_PAYMENTPAGE_FORM";
export const EDIT_SELECTED_PAYMENTPAGE = "EDIT_SELECTED_PAYMENTPAGE";
export const PAYMENTPAGE_FORM_TOOGLE_LOADING = "PAYMENTPAGE_FORM_TOOGLE_LOADING";
/* <---- PAYMENTPAGE ----> */

/* APPLICABLEDISCOUNT */
export const SET_APPLICABLEDISCOUNT_LIST = "SET_APPLICABLEDISCOUNT_LIST";
export const ADD_NEW_APPLICABLEDISCOUNT = "ADD_NEW_APPLICABLEDISCOUNT";
export const UPDATE_APPLICABLEDISCOUNT = "UPDATE_APPLICABLEDISCOUNT";
export const DELETE_APPLICABLEDISCOUNT = "DELETE_APPLICABLEDISCOUNT";
export const OPEN_APPLICABLEDISCOUNT_FORM = "OPEN_APPLICABLEDISCOUNT_FORM";
export const CLOSE_APPLICABLEDISCOUNT_FORM = "CLOSE_APPLICABLEDISCOUNT_FORM";
export const EDIT_SELECTED_APPLICABLEDISCOUNT = "EDIT_SELECTED_APPLICABLEDISCOUNT";
export const APPLICABLEDISCOUNT_FORM_TOOGLE_LOADING = "APPLICABLEDISCOUNT_FORM_TOOGLE_LOADING";
/* <---- APPLICABLEDISCOUNT ----> */

/* ADMINISTRATIVECOST */
export const SET_ADMINISTRATIVECOST_LIST = "SET_ADMINISTRATIVECOST_LIST";
export const ADD_NEW_ADMINISTRATIVECOST = "ADD_NEW_ADMINISTRATIVECOST";
export const UPDATE_ADMINISTRATIVECOST = "UPDATE_ADMINISTRATIVECOST";
export const DELETE_ADMINISTRATIVECOST = "DELETE_ADMINISTRATIVECOST";
export const OPEN_ADMINISTRATIVECOST_FORM = "OPEN_ADMINISTRATIVECOST_FORM";
export const CLOSE_ADMINISTRATIVECOST_FORM = "CLOSE_ADMINISTRATIVECOST_FORM";
export const EDIT_SELECTED_ADMINISTRATIVECOST = "EDIT_SELECTED_ADMINISTRATIVECOST";
export const ADMINISTRATIVECOST_FORM_TOOGLE_LOADING = "ADMINISTRATIVECOST_FORM_TOOGLE_LOADING";
/* <---- ADMINISTRATIVECOST ----> */

/* CREDITTRANSFERCOST */
export const SET_CREDITTRANSFERCOST_LIST = "SET_CREDITTRANSFERCOST_LIST";
export const ADD_NEW_CREDITTRANSFERCOST = "ADD_NEW_CREDITTRANSFERCOST";
export const UPDATE_CREDITTRANSFERCOST = "UPDATE_CREDITTRANSFERCOST";
export const DELETE_CREDITTRANSFERCOST = "DELETE_CREDITTRANSFERCOST";
export const OPEN_CREDITTRANSFERCOST_FORM = "OPEN_CREDITTRANSFERCOST_FORM";
export const CLOSE_CREDITTRANSFERCOST_FORM = "CLOSE_CREDITTRANSFERCOST_FORM";
export const EDIT_SELECTED_CREDITTRANSFERCOST = "EDIT_SELECTED_CREDITTRANSFERCOST";
export const CREDITTRANSFERCOST_FORM_TOOGLE_LOADING = "CREDITTRANSFERCOST_FORM_TOOGLE_LOADING";
/* <---- CREDITTRANSFERCOST ----> */

/* CREDITCOST */
export const SET_CREDITCOST_LIST = "SET_CREDITCOST_LIST";
export const ADD_NEW_CREDITCOST = "ADD_NEW_CREDITCOST";
export const UPDATE_CREDITCOST = "UPDATE_CREDITCOST";
export const DELETE_CREDITCOST = "DELETE_CREDITCOST";
export const OPEN_CREDITCOST_FORM = "OPEN_CREDITCOST_FORM";
export const CLOSE_CREDITCOST_FORM = "CLOSE_CREDITCOST_FORM";
export const EDIT_SELECTED_CREDITCOST = "EDIT_SELECTED_CREDITCOST";
export const CREDITCOST_FORM_TOOGLE_LOADING = "CREDITCOST_FORM_TOOGLE_LOADING";
/* <---- CREDITCOST ----> */

/* CREDITTRANSFERBLUEPRINT */
export const SET_CREDITTRANSFERBLUEPRINT_LIST = "SET_CREDITTRANSFERBLUEPRINT_LIST";
export const ADD_NEW_CREDITTRANSFERBLUEPRINT = "ADD_NEW_CREDITTRANSFERBLUEPRINT";
export const UPDATE_CREDITTRANSFERBLUEPRINT = "UPDATE_CREDITTRANSFERBLUEPRINT";
export const DELETE_CREDITTRANSFERBLUEPRINT = "DELETE_CREDITTRANSFERBLUEPRINT";
export const OPEN_CREDITTRANSFERBLUEPRINT_FORM = "OPEN_CREDITTRANSFERBLUEPRINT_FORM";
export const CLOSE_CREDITTRANSFERBLUEPRINT_FORM = "CLOSE_CREDITTRANSFERBLUEPRINT_FORM";
export const EDIT_SELECTED_CREDITTRANSFERBLUEPRINT = "EDIT_SELECTED_CREDITTRANSFERBLUEPRINT";
export const CREDITTRANSFERBLUEPRINT_FORM_TOOGLE_LOADING = "CREDITTRANSFERBLUEPRINT_FORM_TOOGLE_LOADING";
/* <---- CREDITTRANSFERBLUEPRINT ----> */

/* CREDITTRANSFER */
export const SET_CREDITTRANSFER_LIST = "SET_CREDITTRANSFER_LIST";
export const ADD_NEW_CREDITTRANSFER = "ADD_NEW_CREDITTRANSFER";
export const UPDATE_CREDITTRANSFER = "UPDATE_CREDITTRANSFER";
export const DELETE_CREDITTRANSFER = "DELETE_CREDITTRANSFER";
export const OPEN_CREDITTRANSFER_FORM = "OPEN_CREDITTRANSFER_FORM";
export const CLOSE_CREDITTRANSFER_FORM = "CLOSE_CREDITTRANSFER_FORM";
export const EDIT_SELECTED_CREDITTRANSFER = "EDIT_SELECTED_CREDITTRANSFER";
export const CREDITTRANSFER_FORM_TOOGLE_LOADING = "CREDITTRANSFER_FORM_TOOGLE_LOADING";
/* <---- CREDITTRANSFER ----> */

/* LOG */
export const SET_LOG_LIST = "SET_LOG_LIST";
export const ADD_NEW_LOG = "ADD_NEW_LOG";
export const UPDATE_LOG = "UPDATE_LOG";
export const DELETE_LOG = "DELETE_LOG";
export const OPEN_LOG_FORM = "OPEN_LOG_FORM";
export const CLOSE_LOG_FORM = "CLOSE_LOG_FORM";
export const EDIT_SELECTED_LOG = "EDIT_SELECTED_LOG";
export const LOG_FORM_TOOGLE_LOADING = "LOG_FORM_TOOGLE_LOADING";
/* <---- LOG ----> */

/* PREENROLLMENT */
export const SET_PREENROLLMENT_LIST = "SET_PREENROLLMENT_LIST";
export const ADD_NEW_PREENROLLMENT = "ADD_NEW_PREENROLLMENT";
export const UPDATE_PREENROLLMENT = "UPDATE_PREENROLLMENT";
export const DELETE_PREENROLLMENT = "DELETE_PREENROLLMENT";
export const OPEN_PREENROLLMENT_FORM = "OPEN_PREENROLLMENT_FORM";
export const CLOSE_PREENROLLMENT_FORM = "CLOSE_PREENROLLMENT_FORM";
export const EDIT_SELECTED_PREENROLLMENT = "EDIT_SELECTED_PREENROLLMENT";
export const PREENROLLMENT_FORM_TOOGLE_LOADING = "PREENROLLMENT_FORM_TOOGLE_LOADING";
/* <---- PREENROLLMENT ----> */

/* SUBJECTTYPE */
export const SET_SUBJECTTYPE_LIST = "SET_SUBJECTTYPE_LIST";
export const ADD_NEW_SUBJECTTYPE = "ADD_NEW_SUBJECTTYPE";
export const UPDATE_SUBJECTTYPE = "UPDATE_SUBJECTTYPE";
export const DELETE_SUBJECTTYPE = "DELETE_SUBJECTTYPE";
export const OPEN_SUBJECTTYPE_FORM = "OPEN_SUBJECTTYPE_FORM";
export const CLOSE_SUBJECTTYPE_FORM = "CLOSE_SUBJECTTYPE_FORM";
export const EDIT_SELECTED_SUBJECTTYPE = "EDIT_SELECTED_SUBJECTTYPE";
export const SUBJECTTYPE_FORM_TOOGLE_LOADING = "SUBJECTTYPE_FORM_TOOGLE_LOADING";
/* <---- SUBJECTTYPE ----> */

/* TEACHER */
export const SET_TEACHER_LIST = "SET_TEACHER_LIST";
export const ADD_NEW_TEACHER = "ADD_NEW_TEACHER";
export const UPDATE_TEACHER = "UPDATE_TEACHER";
export const DELETE_TEACHER = "DELETE_TEACHER";
export const OPEN_TEACHER_FORM = "OPEN_TEACHER_FORM";
export const CLOSE_TEACHER_FORM = "CLOSE_TEACHER_FORM";
export const EDIT_SELECTED_TEACHER = "EDIT_SELECTED_TEACHER";
export const TEACHER_FORM_TOOGLE_LOADING = "TEACHER_FORM_TOOGLE_LOADING";
/* <---- TEACHER ----> */

/* PROFESSORROLE */
export const SET_PROFESSORROLE_LIST = "SET_PROFESSORROLE_LIST";
export const ADD_NEW_PROFESSORROLE = "ADD_NEW_PROFESSORROLE";
export const UPDATE_PROFESSORROLE = "UPDATE_PROFESSORROLE";
export const DELETE_PROFESSORROLE = "DELETE_PROFESSORROLE";
export const OPEN_PROFESSORROLE_FORM = "OPEN_PROFESSORROLE_FORM";
export const CLOSE_PROFESSORROLE_FORM = "CLOSE_PROFESSORROLE_FORM";
export const EDIT_SELECTED_PROFESSORROLE = "EDIT_SELECTED_PROFESSORROLE";
export const PROFESSORROLE_FORM_TOOGLE_LOADING = "PROFESSORROLE_FORM_TOOGLE_LOADING";
/* <---- PROFESSORROLE ----> */

/* ACCESS */
export const SET_ACCESS_LIST = "SET_ACCESS_LIST";
export const ADD_NEW_ACCESS = "ADD_NEW_ACCESS";
export const UPDATE_ACCESS = "UPDATE_ACCESS";
export const DELETE_ACCESS = "DELETE_ACCESS";
export const OPEN_ACCESS_FORM = "OPEN_ACCESS_FORM";
export const CLOSE_ACCESS_FORM = "CLOSE_ACCESS_FORM";
export const EDIT_SELECTED_ACCESS = "EDIT_SELECTED_ACCESS";
export const ACCESS_FORM_TOOGLE_LOADING = "ACCESS_FORM_TOOGLE_LOADING";
/* <---- ACCESS ----> */

/* MARK */
export const SET_MARK_LIST = "SET_MARK_LIST";
export const ADD_NEW_MARK = "ADD_NEW_MARK";
export const UPDATE_MARK = "UPDATE_MARK";
export const DELETE_MARK = "DELETE_MARK";
export const OPEN_MARK_FORM = "OPEN_MARK_FORM";
export const CLOSE_MARK_FORM = "CLOSE_MARK_FORM";
export const EDIT_SELECTED_MARK = "EDIT_SELECTED_MARK";
export const MARK_FORM_TOOGLE_LOADING = "MARK_FORM_TOOGLE_LOADING";
/* <---- MARK ----> */

/* STUDENT */
export const SET_STUDENT_LIST = "SET_STUDENT_LIST";
export const ADD_NEW_STUDENT = "ADD_NEW_STUDENT";
export const UPDATE_STUDENT = "UPDATE_STUDENT";
export const DELETE_STUDENT = "DELETE_STUDENT";
export const OPEN_STUDENT_FORM = "OPEN_STUDENT_FORM";
export const CLOSE_STUDENT_FORM = "CLOSE_STUDENT_FORM";
export const EDIT_SELECTED_STUDENT = "EDIT_SELECTED_STUDENT";
export const STUDENT_FORM_TOOGLE_LOADING = "STUDENT_FORM_TOOGLE_LOADING";
/* <---- STUDENT ----> */

/* EDITIONXSUBJECT */
export const SET_EDITIONXSUBJECT_LIST = "SET_EDITIONXSUBJECT_LIST";
export const ADD_NEW_EDITIONXSUBJECT = "ADD_NEW_EDITIONXSUBJECT";
export const UPDATE_EDITIONXSUBJECT = "UPDATE_EDITIONXSUBJECT";
export const DELETE_EDITIONXSUBJECT = "DELETE_EDITIONXSUBJECT";
export const OPEN_EDITIONXSUBJECT_FORM = "OPEN_EDITIONXSUBJECT_FORM";
export const CLOSE_EDITIONXSUBJECT_FORM = "CLOSE_EDITIONXSUBJECT_FORM";
export const EDIT_SELECTED_EDITIONXSUBJECT = "EDIT_SELECTED_EDITIONXSUBJECT";
export const EDITIONXSUBJECT_FORM_TOOGLE_LOADING = "EDITIONXSUBJECT_FORM_TOOGLE_LOADING";
/* <---- EDITIONXSUBJECT ----> */

/* EDITION */
export const SET_EDITION_LIST = "SET_EDITION_LIST";
export const ADD_NEW_EDITION = "ADD_NEW_EDITION";
export const UPDATE_EDITION = "UPDATE_EDITION";
export const DELETE_EDITION = "DELETE_EDITION";
export const OPEN_EDITION_FORM = "OPEN_EDITION_FORM";
export const CLOSE_EDITION_FORM = "CLOSE_EDITION_FORM";
export const EDIT_SELECTED_EDITION = "EDIT_SELECTED_EDITION";
export const EDITION_FORM_TOOGLE_LOADING = "EDITION_FORM_TOOGLE_LOADING";
/* <---- EDITION ----> */

/* BOOKTYPE */
export const SET_BOOKTYPE_LIST = "SET_BOOKTYPE_LIST";
export const ADD_NEW_BOOKTYPE = "ADD_NEW_BOOKTYPE";
export const UPDATE_BOOKTYPE = "UPDATE_BOOKTYPE";
export const DELETE_BOOKTYPE = "DELETE_BOOKTYPE";
export const OPEN_BOOKTYPE_FORM = "OPEN_BOOKTYPE_FORM";
export const CLOSE_BOOKTYPE_FORM = "CLOSE_BOOKTYPE_FORM";
export const EDIT_SELECTED_BOOKTYPE = "EDIT_SELECTED_BOOKTYPE";
export const BOOKTYPE_FORM_TOOGLE_LOADING = "BOOKTYPE_FORM_TOOGLE_LOADING";
/* <---- BOOKTYPE ----> */

/* STUDIE */
export const SET_STUDIE_LIST = "SET_STUDIE_LIST";
export const ADD_NEW_STUDIE = "ADD_NEW_STUDIE";
export const UPDATE_STUDIE = "UPDATE_STUDIE";
export const DELETE_STUDIE = "DELETE_STUDIE";
export const OPEN_STUDIE_FORM = "OPEN_STUDIE_FORM";
export const CLOSE_STUDIE_FORM = "CLOSE_STUDIE_FORM";
export const EDIT_SELECTED_STUDIE = "EDIT_SELECTED_STUDIE";
export const STUDIE_FORM_TOOGLE_LOADING = "STUDIE_FORM_TOOGLE_LOADING";
/* <---- STUDIE ----> */

/* RESOURCE */
export const SET_RESOURCE_LIST = "SET_RESOURCE_LIST";
export const ADD_NEW_RESOURCE = "ADD_NEW_RESOURCE";
export const UPDATE_RESOURCE = "UPDATE_RESOURCE";
export const DELETE_RESOURCE = "DELETE_RESOURCE";
export const OPEN_RESOURCE_FORM = "OPEN_RESOURCE_FORM";
export const CLOSE_RESOURCE_FORM = "CLOSE_RESOURCE_FORM";
export const EDIT_SELECTED_RESOURCE = "EDIT_SELECTED_RESOURCE";
export const RESOURCE_FORM_TOOGLE_LOADING = "RESOURCE_FORM_TOOGLE_LOADING";
/* <---- RESOURCE ----> */

/* GRADE */
export const SET_GRADE_LIST = "SET_GRADE_LIST";
export const ADD_NEW_GRADE = "ADD_NEW_GRADE";
export const UPDATE_GRADE = "UPDATE_GRADE";
export const DELETE_GRADE = "DELETE_GRADE";
export const OPEN_GRADE_FORM = "OPEN_GRADE_FORM";
export const CLOSE_GRADE_FORM = "CLOSE_GRADE_FORM";
export const EDIT_SELECTED_GRADE = "EDIT_SELECTED_GRADE";
export const GRADE_FORM_TOOGLE_LOADING = "GRADE_FORM_TOOGLE_LOADING";
/* <---- GRADE ----> */

/* BOOKING */
export const SET_BOOKING_LIST = "SET_BOOKING_LIST";
export const ADD_NEW_BOOKING = "ADD_NEW_BOOKING";
export const UPDATE_BOOKING = "UPDATE_BOOKING";
export const DELETE_BOOKING = "DELETE_BOOKING";
export const OPEN_BOOKING_FORM = "OPEN_BOOKING_FORM";
export const CLOSE_BOOKING_FORM = "CLOSE_BOOKING_FORM";
export const EDIT_SELECTED_BOOKING = "EDIT_SELECTED_BOOKING";
export const BOOKING_FORM_TOOGLE_LOADING = "BOOKING_FORM_TOOGLE_LOADING";
/* <---- BOOKING ----> */

/* SUBJECT */
export const SET_SUBJECT_LIST = "SET_SUBJECT_LIST";
export const ADD_NEW_SUBJECT = "ADD_NEW_SUBJECT";
export const UPDATE_SUBJECT = "UPDATE_SUBJECT";
export const DELETE_SUBJECT = "DELETE_SUBJECT";
export const OPEN_SUBJECT_FORM = "OPEN_SUBJECT_FORM";
export const CLOSE_SUBJECT_FORM = "CLOSE_SUBJECT_FORM";
export const EDIT_SELECTED_SUBJECT = "EDIT_SELECTED_SUBJECT";
export const SUBJECT_FORM_TOOGLE_LOADING = "SUBJECT_FORM_TOOGLE_LOADING";
/* <---- SUBJECT ----> */

/* CLASSROOM */
export const SET_CLASSROOM_LIST = "SET_CLASSROOM_LIST";
export const ADD_NEW_CLASSROOM = "ADD_NEW_CLASSROOM";
export const UPDATE_CLASSROOM = "UPDATE_CLASSROOM";
export const DELETE_CLASSROOM = "DELETE_CLASSROOM";
export const OPEN_CLASSROOM_FORM = "OPEN_CLASSROOM_FORM";
export const CLOSE_CLASSROOM_FORM = "CLOSE_CLASSROOM_FORM";
export const EDIT_SELECTED_CLASSROOM = "EDIT_SELECTED_CLASSROOM";
export const CLASSROOM_FORM_TOOGLE_LOADING = "CLASSROOM_FORM_TOOGLE_LOADING";
/* <---- CLASSROOM ----> */

/* COURSE */
export const SET_COURSE_LIST = "SET_COURSE_LIST";
export const ADD_NEW_COURSE = "ADD_NEW_COURSE";
export const UPDATE_COURSE = "UPDATE_COURSE";
export const DELETE_COURSE = "DELETE_COURSE";
export const OPEN_COURSE_FORM = "OPEN_COURSE_FORM";
export const CLOSE_COURSE_FORM = "CLOSE_COURSE_FORM";
export const EDIT_SELECTED_COURSE = "EDIT_SELECTED_COURSE";
export const COURSE_FORM_TOOGLE_LOADING = "COURSE_FORM_TOOGLE_LOADING";
/* <---- COURSE ----> */

/* ROUTES */
export const SET_ALL_ROUTES = "SET_ALL_ROUTES";
export const UPDATE_ROUTE = "UPDATE_ROUTE";
/* <---- ROUTES ----> */

/* ALERT */
export const SET_ALERT_LIST = "SET_ALERT_LIST";
export const ADD_NEW_ALERT = "ADD_NEW_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const OPEN_ALERT_FORM = "OPEN_ALERT_FORM";
export const CLOSE_ALERT_FORM = "CLOSE_ALERT_FORM";
export const EDIT_SELECTED_ALERT = "EDIT_SELECTED_ALERT";
export const ALERT_FORM_TOOGLE_LOADING = "ALERT_FORM_TOOGLE_LOADING";
/* <---- ALERT ----> */
