import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_APPLICABLEDISCOUNT,
    DELETE_APPLICABLEDISCOUNT,
    APPLICABLEDISCOUNT_FORM_TOOGLE_LOADING,
    SET_APPLICABLEDISCOUNT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_APPLICABLEDISCOUNT,
} from "../constants";
import { formatApplicablediscounts } from "./settingsActionsUtils";

/* APPLICABLEDISCOUNT LIST */
export const fetchApplicablediscounts = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.applicablediscounts.list)
            .then((response) => {
                const applicablediscounts = formatApplicablediscounts(response.data);
                dispatch({
                    type: SET_APPLICABLEDISCOUNT_LIST,
                    payload: keyBy(applicablediscounts, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// APPLICABLEDISCOUNTS
export const createApplicablediscount = (newApplicablediscount) => {
    return async (dispatch) => {
        dispatch({ type: APPLICABLEDISCOUNT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.applicablediscounts.create, newApplicablediscount)
            .then((response) => {
                const applicablediscount = formatApplicablediscounts(response.data);
                dispatch({ type: ADD_NEW_APPLICABLEDISCOUNT, payload: applicablediscount });
                dispatch({ type: APPLICABLEDISCOUNT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Descuento aplicable creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: APPLICABLEDISCOUNT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateApplicablediscount = (updatedApplicablediscount) => {
    return async (dispatch) => {
        dispatch({ type: APPLICABLEDISCOUNT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.applicablediscounts.edit}${updatedApplicablediscount && updatedApplicablediscount._id}`, updatedApplicablediscount)
            .then((response) => {
                const applicablediscount = formatApplicablediscounts(response.data);
                dispatch({ type: UPDATE_APPLICABLEDISCOUNT, payload: applicablediscount });
                dispatch({ type: APPLICABLEDISCOUNT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Descuento aplicable actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: APPLICABLEDISCOUNT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteApplicablediscounts = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: APPLICABLEDISCOUNT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.applicablediscounts.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_APPLICABLEDISCOUNT, payload: Ids });
                dispatch({ type: APPLICABLEDISCOUNT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Descuento aplicable eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: APPLICABLEDISCOUNT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
