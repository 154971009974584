import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_PROFESSORROLE,
    DELETE_PROFESSORROLE,
    PROFESSORROLE_FORM_TOOGLE_LOADING,
    SET_PROFESSORROLE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_PROFESSORROLE,
} from "../constants";
import { formatProfessorRoles } from "./settingsActionsUtils";

/* PROFESSORROLE LIST */
export const fetchProfessorRoles = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.professorRoles.list)
            .then((response) => {
                const professorRoles = formatProfessorRoles(response.data);
                dispatch({
                    type: SET_PROFESSORROLE_LIST,
                    payload: keyBy(professorRoles, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// PROFESSORROLES
export const createProfessorRole = (newProfessorRole) => {
    return async (dispatch) => {
        dispatch({ type: PROFESSORROLE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.professorRoles.create, newProfessorRole)
            .then((response) => {
                const professorRole = formatProfessorRoles(response.data);
                dispatch({ type: ADD_NEW_PROFESSORROLE, payload: professorRole });
                dispatch({ type: PROFESSORROLE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Roles profesor creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PROFESSORROLE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateProfessorRole = (updatedProfessorRole) => {
    return async (dispatch) => {
        dispatch({ type: PROFESSORROLE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.professorRoles.edit}${updatedProfessorRole && updatedProfessorRole._id}`, updatedProfessorRole)
            .then((response) => {
                const professorRole = formatProfessorRoles(response.data);
                dispatch({ type: UPDATE_PROFESSORROLE, payload: professorRole });
                dispatch({ type: PROFESSORROLE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Roles profesor actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PROFESSORROLE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteProfessorRoles = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: PROFESSORROLE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.professorRoles.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_PROFESSORROLE, payload: Ids });
                dispatch({ type: PROFESSORROLE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Roles profesor eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PROFESSORROLE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
