import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_BOOKING,
    DELETE_BOOKING,
    BOOKING_FORM_TOOGLE_LOADING,
    SET_BOOKING_LIST,
    SHOW_NOTIFICATION,
    UPDATE_BOOKING,
} from "../constants";
import { formatBookings } from "./settingsActionsUtils";

/* BOOKING LIST */
export const fetchBookings = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.bookings.list)
            .then((response) => {
                const bookings = formatBookings(response.data);
                dispatch({
                    type: SET_BOOKING_LIST,
                    payload: keyBy(bookings, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// BOOKINGS
export const createBooking = (newBooking, hidemessage) => {
    return async (dispatch) => {
        dispatch({ type: BOOKING_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.bookings.create, newBooking)
            .then((response) => {
                const booking = formatBookings(response.data);
                dispatch({ type: ADD_NEW_BOOKING, payload: booking });
                dispatch({ type: BOOKING_FORM_TOOGLE_LOADING });
                if (!hidemessage) {
                    dispatch({
                        type: SHOW_NOTIFICATION,
                        payload: {
                            show: true,
                            status: "success",
                            message: "Reserva creada con éxito.",
                        },
                    });
                }
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: BOOKING_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateBooking = (updatedBooking) => {
    return async (dispatch) => {
        dispatch({ type: BOOKING_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.bookings.edit}${updatedBooking && updatedBooking._id}`, updatedBooking)
            .then((response) => {
                const booking = formatBookings(response.data);
                dispatch({ type: UPDATE_BOOKING, payload: booking });
                dispatch({ type: BOOKING_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Reserva actualizada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: BOOKING_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteBookings = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: BOOKING_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.bookings.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_BOOKING, payload: Ids });
                dispatch({ type: BOOKING_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Reserva eliminada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: BOOKING_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
