import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_STUDIE,
    DELETE_STUDIE,
    STUDIE_FORM_TOOGLE_LOADING,
    SET_STUDIE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_STUDIE,
} from "../constants";
import { formatStudies } from "./settingsActionsUtils";

/* STUDIE LIST */
export const fetchStudies = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.studies.list)
            .then((response) => {
                const studies = formatStudies(response.data);
                dispatch({
                    type: SET_STUDIE_LIST,
                    payload: keyBy(studies, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// STUDIES
export const createStudie = (newStudie) => {
    return async (dispatch) => {
        dispatch({ type: STUDIE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.studies.create, newStudie)
            .then((response) => {
                const studie = formatStudies(response.data);
                dispatch({ type: ADD_NEW_STUDIE, payload: studie });
                dispatch({ type: STUDIE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Estudio creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: STUDIE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateStudie = (updatedStudie) => {
    return async (dispatch) => {
        dispatch({ type: STUDIE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.studies.edit}${updatedStudie && updatedStudie._id}`, updatedStudie)
            .then((response) => {
                const studie = formatStudies(response.data);
                dispatch({ type: UPDATE_STUDIE, payload: studie });
                dispatch({ type: STUDIE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Estudio actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: STUDIE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteStudies = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: STUDIE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.studies.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_STUDIE, payload: Ids });
                dispatch({ type: STUDIE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Estudio eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: STUDIE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
