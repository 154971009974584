import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_GRADE,
    DELETE_GRADE,
    GRADE_FORM_TOOGLE_LOADING,
    SET_GRADE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_GRADE,
} from "../constants";
import { formatGrades } from "./settingsActionsUtils";

/* GRADE LIST */
export const fetchGrades = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.grades.list)
            .then((response) => {
                const grades = formatGrades(response.data);
                dispatch({
                    type: SET_GRADE_LIST,
                    payload: keyBy(grades, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// GRADES
export const createGrade = (newGrade) => {
    return async (dispatch) => {
        dispatch({ type: GRADE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.grades.create, newGrade)
            .then((response) => {
                const grade = formatGrades(response.data);
                dispatch({ type: ADD_NEW_GRADE, payload: grade });
                dispatch({ type: GRADE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Grado creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: GRADE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateGrade = (updatedGrade) => {
    return async (dispatch) => {
        dispatch({ type: GRADE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.grades.edit}${updatedGrade && updatedGrade._id}`, updatedGrade)
            .then((response) => {
                const grade = formatGrades(response.data);
                dispatch({ type: UPDATE_GRADE, payload: grade });
                dispatch({ type: GRADE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Grado actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: GRADE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteGrades = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: GRADE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.grades.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_GRADE, payload: Ids });
                dispatch({ type: GRADE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Grado eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: GRADE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
