import { handleAction, handleActions } from "redux-actions";
import {
  ACCESS_FORM_TOOGLE_LOADING,
  ADD_NEW_ACCESS,
  ADD_NEW_ALERT,
  ADD_NEW_BOOKING,
  ADD_NEW_BOOKTYPE,
  ADD_NEW_CLASSROOM,
  ADD_NEW_COURSE,
  ADD_NEW_EDITION,
  ADD_NEW_EDITIONXSUBJECT,
  ADD_NEW_GRADE,
  ADD_NEW_MARK,
  ADD_NEW_PROFESSORROLE,
  ADD_NEW_RESOURCE,
  ADD_NEW_STUDENT,
  ADD_NEW_STUDIE,
  ADD_NEW_SUBJECT,
  ADD_NEW_TEACHER,
  ADD_NEW_USER,
  ALERT_FORM_TOOGLE_LOADING,
  AUTH,
  BOOKING_FORM_TOOGLE_LOADING,
  BOOKTYPE_FORM_TOOGLE_LOADING,
  CLASSROOM_FORM_TOOGLE_LOADING,
  CLOSE_ACCESS_FORM,
  CLOSE_ALERT_FORM,
  CLOSE_BOOKING_FORM,
  CLOSE_BOOKTYPE_FORM,
  CLOSE_CLASSROOM_FORM,
  CLOSE_COURSE_FORM,
  CLOSE_EDITIONXSUBJECT_FORM,
  CLOSE_EDITION_FORM,
  CLOSE_GRADE_FORM,
  CLOSE_MARK_FORM,
  CLOSE_NOTIFICATION,
  CLOSE_PROFESSORROLE_FORM,
  CLOSE_RESOURCE_FORM,
  CLOSE_STUDENT_FORM,
  CLOSE_STUDIE_FORM,
  CLOSE_SUBJECT_FORM,
  CLOSE_TEACHER_FORM,
  CLOSE_USERS_FORM,
  COURSE_FORM_TOOGLE_LOADING,
  DELETE_ACCESS,
  DELETE_ALERT,
  DELETE_BOOKING,
  DELETE_BOOKTYPE,
  DELETE_CLASSROOM,
  DELETE_COURSE,
  DELETE_EDITION,
  DELETE_EDITIONXSUBJECT,
  DELETE_GRADE,
  DELETE_MARK,
  DELETE_PROFESSORROLE,
  DELETE_RESOURCE,
  DELETE_STUDENT,
  DELETE_STUDIE,
  DELETE_SUBJECT,
  DELETE_TEACHER,
  DELETE_USER,
  EDITIONXSUBJECT_FORM_TOOGLE_LOADING,
  EDITION_FORM_TOOGLE_LOADING,
  EDIT_SELECTED_ACCESS,
  EDIT_SELECTED_ALERT,
  EDIT_SELECTED_BOOKING,
  EDIT_SELECTED_BOOKTYPE,
  EDIT_SELECTED_CLASSROOM,
  EDIT_SELECTED_COURSE,
  EDIT_SELECTED_EDITION,
  EDIT_SELECTED_EDITIONXSUBJECT,
  EDIT_SELECTED_GRADE,
  EDIT_SELECTED_MARK,
  EDIT_SELECTED_PROFESSORROLE,
  EDIT_SELECTED_RESOURCE,
  EDIT_SELECTED_STUDENT,
  EDIT_SELECTED_STUDIE,
  EDIT_SELECTED_SUBJECT,
  EDIT_SELECTED_TEACHER,
  EDIT_SELECTED_USER,
  GRADE_FORM_TOOGLE_LOADING,
  LOGOUT,
  MARK_FORM_TOOGLE_LOADING,
  OPEN_ACCESS_FORM,
  OPEN_ALERT_FORM,
  OPEN_BOOKING_FORM,
  OPEN_BOOKTYPE_FORM,
  OPEN_CLASSROOM_FORM,
  OPEN_COURSE_FORM,
  OPEN_EDITIONXSUBJECT_FORM,
  OPEN_EDITION_FORM,
  OPEN_GRADE_FORM,
  OPEN_MARK_FORM,
  OPEN_PROFESSORROLE_FORM,
  OPEN_RESOURCE_FORM,
  OPEN_STUDENT_FORM,
  OPEN_STUDIE_FORM,
  OPEN_SUBJECT_FORM,
  OPEN_TEACHER_FORM,
  OPEN_USERS_FORM,
  PROFESSORROLE_FORM_TOOGLE_LOADING,
  RESOURCE_FORM_TOOGLE_LOADING,
  SET_ACCESS_LIST,
  SET_ALERT_LIST,
  SET_BOOKING_LIST,
  SET_BOOKTYPE_LIST,
  SET_CLASSROOM_LIST,
  SET_COURSE_LIST,
  SET_CURRENT_ROUTE,
  SET_EDITIONXSUBJECT_LIST,
  SET_EDITION_LIST,
  SET_GRADE_LIST,
  SET_MARK_LIST,
  SET_PROFESSORROLE_LIST,
  SET_PROFILE,
  SET_RESOURCE_LIST,
  SET_SIDE_MENU_STATE,
  SET_STUDENT_LIST,
  SET_STUDIE_LIST,
  SET_SUBJECT_LIST,
  SET_TEACHER_LIST,
  SET_USER_LIST,
  SHOW_NOTIFICATION,
  STUDENT_FORM_TOOGLE_LOADING,
  STUDIE_FORM_TOOGLE_LOADING,
  SUBJECT_FORM_TOOGLE_LOADING,
  TEACHER_FORM_TOOGLE_LOADING,
  THEME,
  UPDATE_ACCESS,
  UPDATE_ALERT,
  UPDATE_BOOKING,
  UPDATE_BOOKTYPE,
  UPDATE_CLASSROOM,
  UPDATE_COURSE,
  UPDATE_EDITION,
  UPDATE_EDITIONXSUBJECT,
  UPDATE_GRADE,
  UPDATE_MARK,
  UPDATE_PROFESSORROLE,
  UPDATE_RESOURCE,
  UPDATE_STUDENT,
  UPDATE_STUDIE,
  UPDATE_SUBJECT,
  UPDATE_TEACHER,
  UPDATE_USER,
  USERS_FORM_TOOGLE_LOADING,
  ADD_NEW_SUBJECTTYPE,
  SET_SUBJECTTYPE_LIST,
  UPDATE_SUBJECTTYPE,
  DELETE_SUBJECTTYPE,
  OPEN_SUBJECTTYPE_FORM,
  CLOSE_SUBJECTTYPE_FORM,
  EDIT_SELECTED_SUBJECTTYPE,
  SUBJECTTYPE_FORM_TOOGLE_LOADING,
  ADD_NEW_PREENROLLMENT,
  SET_PREENROLLMENT_LIST,
  UPDATE_PREENROLLMENT,
  DELETE_PREENROLLMENT,
  OPEN_PREENROLLMENT_FORM,
  CLOSE_PREENROLLMENT_FORM,
  EDIT_SELECTED_PREENROLLMENT,
  PREENROLLMENT_FORM_TOOGLE_LOADING,
  ADD_NEW_LOG,
  SET_LOG_LIST,
  UPDATE_LOG,
  DELETE_LOG,
  OPEN_LOG_FORM,
  CLOSE_LOG_FORM,
  EDIT_SELECTED_LOG,
  LOG_FORM_TOOGLE_LOADING,
  ADD_NEW_CREDITTRANSFER,
  SET_CREDITTRANSFER_LIST,
  UPDATE_CREDITTRANSFER,
  DELETE_CREDITTRANSFER,
  OPEN_CREDITTRANSFER_FORM,
  CLOSE_CREDITTRANSFER_FORM,
  EDIT_SELECTED_CREDITTRANSFER,
  CREDITTRANSFER_FORM_TOOGLE_LOADING,
  ADD_NEW_CREDITTRANSFERBLUEPRINT,
  SET_CREDITTRANSFERBLUEPRINT_LIST,
  UPDATE_CREDITTRANSFERBLUEPRINT,
  DELETE_CREDITTRANSFERBLUEPRINT,
  OPEN_CREDITTRANSFERBLUEPRINT_FORM,
  CLOSE_CREDITTRANSFERBLUEPRINT_FORM,
  EDIT_SELECTED_CREDITTRANSFERBLUEPRINT,
  CREDITTRANSFERBLUEPRINT_FORM_TOOGLE_LOADING,
  ADD_NEW_CREDITCOST,
  SET_CREDITCOST_LIST,
  UPDATE_CREDITCOST,
  DELETE_CREDITCOST,
  OPEN_CREDITCOST_FORM,
  CLOSE_CREDITCOST_FORM,
  EDIT_SELECTED_CREDITCOST,
  CREDITCOST_FORM_TOOGLE_LOADING,
  ADD_NEW_CREDITTRANSFERCOST,
  SET_CREDITTRANSFERCOST_LIST,
  UPDATE_CREDITTRANSFERCOST,
  DELETE_CREDITTRANSFERCOST,
  OPEN_CREDITTRANSFERCOST_FORM,
  CLOSE_CREDITTRANSFERCOST_FORM,
  EDIT_SELECTED_CREDITTRANSFERCOST,
  CREDITTRANSFERCOST_FORM_TOOGLE_LOADING,
  ADD_NEW_ADMINISTRATIVECOST,
  SET_ADMINISTRATIVECOST_LIST,
  UPDATE_ADMINISTRATIVECOST,
  DELETE_ADMINISTRATIVECOST,
  OPEN_ADMINISTRATIVECOST_FORM,
  CLOSE_ADMINISTRATIVECOST_FORM,
  EDIT_SELECTED_ADMINISTRATIVECOST,
  ADMINISTRATIVECOST_FORM_TOOGLE_LOADING,
  ADD_NEW_APPLICABLEDISCOUNT,
  SET_APPLICABLEDISCOUNT_LIST,
  UPDATE_APPLICABLEDISCOUNT,
  DELETE_APPLICABLEDISCOUNT,
  OPEN_APPLICABLEDISCOUNT_FORM,
  CLOSE_APPLICABLEDISCOUNT_FORM,
  EDIT_SELECTED_APPLICABLEDISCOUNT,
  APPLICABLEDISCOUNT_FORM_TOOGLE_LOADING,
  ADD_NEW_PAYMENTPAGE,
  SET_PAYMENTPAGE_LIST,
  UPDATE_PAYMENTPAGE,
  DELETE_PAYMENTPAGE,
  OPEN_PAYMENTPAGE_FORM,
  CLOSE_PAYMENTPAGE_FORM,
  EDIT_SELECTED_PAYMENTPAGE,
  PAYMENTPAGE_FORM_TOOGLE_LOADING,
  ADD_NEW_CONFIGURATION,
  SET_CONFIGURATION_LIST,
  UPDATE_CONFIGURATION,
  DELETE_CONFIGURATION,
  OPEN_CONFIGURATION_FORM,
  CLOSE_CONFIGURATION_FORM,
  EDIT_SELECTED_CONFIGURATION,
  CONFIGURATION_FORM_TOOGLE_LOADING,
  ADD_NEW_PAYMENTSHEET,
  SET_PAYMENTSHEET_LIST,
  UPDATE_PAYMENTSHEET,
  DELETE_PAYMENTSHEET,
  OPEN_PAYMENTSHEET_FORM,
  CLOSE_PAYMENTSHEET_FORM,
  EDIT_SELECTED_PAYMENTSHEET,
  PAYMENTSHEET_FORM_TOOGLE_LOADING,
  ADD_NEW_SCHOLARSHIP,
  SET_SCHOLARSHIP_LIST,
  UPDATE_SCHOLARSHIP,
  DELETE_SCHOLARSHIP,
  OPEN_SCHOLARSHIP_FORM,
  CLOSE_SCHOLARSHIP_FORM,
  EDIT_SELECTED_SCHOLARSHIP,
  SCHOLARSHIP_FORM_TOOGLE_LOADING,
  ADD_NEW_GROUP,
  SET_GROUP_LIST,
  UPDATE_GROUP,
  DELETE_GROUP,
  OPEN_GROUP_FORM,
  CLOSE_GROUP_FORM,
  EDIT_SELECTED_GROUP,
  GROUP_FORM_TOOGLE_LOADING,
} from "./../../constants";

/* BASIC */
export const authorization = handleActions(
  {
    [AUTH]: (state, action) => action.payload,
    [LOGOUT]: () => ({ auth: false }),
  },
  null
);

/* PROFILE */
export const profile = handleActions(
  {
    [SET_PROFILE]: (state, action) => action.payload,
  },
  null
);

/* APP */
export const currentRoute = handleAction(
  SET_CURRENT_ROUTE,
  (state, action) => action.payload,
  {}
);

export const notifications = handleActions(
  {
    [SHOW_NOTIFICATION]: (state, action) => action.payload,
    [CLOSE_NOTIFICATION]: (state, action) => ({
      show: false,
      status: null,
      message: null,
    }),
  },
  { show: false, status: null, message: null }
);

/* APP */
export const app = handleActions(
  {
    [SET_SIDE_MENU_STATE]: (state, action) => {
      return { ...state, isCollapsedSideMenu: action.payload };
    },
  },
  { isCollapsedSideMenu: true }
);

export const currentTheme = handleAction(
  THEME,
  (state, action) => action.payload,
  false
);

export const users = handleActions(
  {
    [SET_USER_LIST]: (state, action) => action.payload,
    [ADD_NEW_USER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_USER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_USER]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const usersForm = handleActions(
  {
    [OPEN_USERS_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_USERS_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_USER]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [USERS_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const alerts = handleActions(
  {
    [SET_ALERT_LIST]: (state, action) => action.payload,
    [ADD_NEW_ALERT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_ALERT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_ALERT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const alertsForm = handleActions(
  {
    [OPEN_ALERT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ALERT_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_ALERT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ALERT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// COURSES
export const courses = handleActions(
  {
    [SET_COURSE_LIST]: (state, action) => action.payload,
    [ADD_NEW_COURSE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_COURSE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_COURSE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const coursesForm = handleActions(
  {
    [OPEN_COURSE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_COURSE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_COURSE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [COURSE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// CLASSROOMS
export const classrooms = handleActions(
  {
    [SET_CLASSROOM_LIST]: (state, action) => action.payload,
    [ADD_NEW_CLASSROOM]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_CLASSROOM]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_CLASSROOM]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const classroomsForm = handleActions(
  {
    [OPEN_CLASSROOM_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_CLASSROOM_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_CLASSROOM]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [CLASSROOM_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// SUBJECTS
export const subjects = handleActions(
  {
    [SET_SUBJECT_LIST]: (state, action) => action.payload,
    [ADD_NEW_SUBJECT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_SUBJECT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_SUBJECT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const subjectsForm = handleActions(
  {
    [OPEN_SUBJECT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_SUBJECT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_SUBJECT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [SUBJECT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// BOOKINGS
export const bookings = handleActions(
  {
    [SET_BOOKING_LIST]: (state, action) => action.payload,
    [ADD_NEW_BOOKING]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_BOOKING]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_BOOKING]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const bookingsForm = handleActions(
  {
    [OPEN_BOOKING_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_BOOKING_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_BOOKING]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [BOOKING_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// GRADES
export const grades = handleActions(
  {
    [SET_GRADE_LIST]: (state, action) => action.payload,
    [ADD_NEW_GRADE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_GRADE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_GRADE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const gradesForm = handleActions(
  {
    [OPEN_GRADE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_GRADE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_GRADE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [GRADE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// RESOURCES
export const resources = handleActions(
  {
    [SET_RESOURCE_LIST]: (state, action) => action.payload,
    [ADD_NEW_RESOURCE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_RESOURCE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_RESOURCE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const resourcesForm = handleActions(
  {
    [OPEN_RESOURCE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_RESOURCE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_RESOURCE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [RESOURCE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// STUDIES
export const studies = handleActions(
  {
    [SET_STUDIE_LIST]: (state, action) => action.payload,
    [ADD_NEW_STUDIE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_STUDIE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_STUDIE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const studiesForm = handleActions(
  {
    [OPEN_STUDIE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_STUDIE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_STUDIE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [STUDIE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// BOOKTYPES
export const booktypes = handleActions(
  {
    [SET_BOOKTYPE_LIST]: (state, action) => action.payload,
    [ADD_NEW_BOOKTYPE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_BOOKTYPE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_BOOKTYPE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const booktypesForm = handleActions(
  {
    [OPEN_BOOKTYPE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_BOOKTYPE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_BOOKTYPE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [BOOKTYPE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// EDITIONS
export const editions = handleActions(
  {
    [SET_EDITION_LIST]: (state, action) => action.payload,
    [ADD_NEW_EDITION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_EDITION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_EDITION]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const editionsForm = handleActions(
  {
    [OPEN_EDITION_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_EDITION_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_EDITION]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [EDITION_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// EDITIONXSUBJECTS
export const editionxsubjects = handleActions(
  {
    [SET_EDITIONXSUBJECT_LIST]: (state, action) => action.payload,
    [ADD_NEW_EDITIONXSUBJECT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_EDITIONXSUBJECT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_EDITIONXSUBJECT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const editionxsubjectsForm = handleActions(
  {
    [OPEN_EDITIONXSUBJECT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_EDITIONXSUBJECT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_EDITIONXSUBJECT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [EDITIONXSUBJECT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);
// STUDENTS
export const students = handleActions(
  {
    [SET_STUDENT_LIST]: (state, action) => action.payload,
    [ADD_NEW_STUDENT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_STUDENT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_STUDENT]: (state, action) => {
      const newState = { ...state };
      action.payload.forEach((id) => {
        if (newState[id]) {
          newState[id] = { ...newState[id], deleted: true };
        }
      });
      return newState;
    },
  },
  null
);

export const studentsForm = handleActions(
  {
    [OPEN_STUDENT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_STUDENT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_STUDENT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [STUDENT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// MARKS
export const marks = handleActions(
  {
    [SET_MARK_LIST]: (state, action) => action.payload,
    [ADD_NEW_MARK]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_MARK]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_MARK]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const marksForm = handleActions(
  {
    [OPEN_MARK_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_MARK_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_MARK]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [MARK_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// ACCESSS
export const accesss = handleActions(
  {
    [SET_ACCESS_LIST]: (state, action) => action.payload,
    [ADD_NEW_ACCESS]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_ACCESS]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_ACCESS]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const accesssForm = handleActions(
  {
    [OPEN_ACCESS_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ACCESS_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_ACCESS]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ACCESS_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// PROFESSORROLES
export const professorRoles = handleActions(
  {
    [SET_PROFESSORROLE_LIST]: (state, action) => action.payload,
    [ADD_NEW_PROFESSORROLE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_PROFESSORROLE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_PROFESSORROLE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const professorRolesForm = handleActions(
  {
    [OPEN_PROFESSORROLE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_PROFESSORROLE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_PROFESSORROLE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [PROFESSORROLE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// TEACHERS
export const teachers = handleActions(
  {
    [SET_TEACHER_LIST]: (state, action) => action.payload,
    [ADD_NEW_TEACHER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_TEACHER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_TEACHER]: (state, action) => {
      const newState = { ...state };
      action.payload.forEach((id) => {
        if (newState[id]) {
          newState[id] = { ...newState[id], deleted: true };
        }
      });
      return newState;
    },
  },
  null
);

export const teachersForm = handleActions(
  {
    [OPEN_TEACHER_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_TEACHER_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_TEACHER]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [TEACHER_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// SUBJECTTYPES
export const subjectTypes = handleActions(
  {
    [SET_SUBJECTTYPE_LIST]: (state, action) => action.payload,
    [ADD_NEW_SUBJECTTYPE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_SUBJECTTYPE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_SUBJECTTYPE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const subjectTypesForm = handleActions(
  {
    [OPEN_SUBJECTTYPE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_SUBJECTTYPE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_SUBJECTTYPE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [SUBJECTTYPE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// PREENROLLMENTS
export const preenrollments = handleActions(
  {
    [SET_PREENROLLMENT_LIST]: (state, action) => action.payload,
    [ADD_NEW_PREENROLLMENT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_PREENROLLMENT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_PREENROLLMENT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const preenrollmentsForm = handleActions(
  {
    [OPEN_PREENROLLMENT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_PREENROLLMENT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_PREENROLLMENT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [PREENROLLMENT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// LOGS
export const logs = handleActions(
  {
    [SET_LOG_LIST]: (state, action) => action.payload,
    [ADD_NEW_LOG]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_LOG]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_LOG]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const logsForm = handleActions(
  {
    [OPEN_LOG_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_LOG_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_LOG]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [LOG_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// CREDITTRANSFERS
export const creditTransfers = handleActions(
  {
    [SET_CREDITTRANSFER_LIST]: (state, action) => action.payload,
    [ADD_NEW_CREDITTRANSFER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_CREDITTRANSFER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_CREDITTRANSFER]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const creditTransfersForm = handleActions(
  {
    [OPEN_CREDITTRANSFER_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_CREDITTRANSFER_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_CREDITTRANSFER]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [CREDITTRANSFER_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// CREDITTRANSFERBLUEPRINTS
export const creditTransferBlueprints = handleActions(
  {
    [SET_CREDITTRANSFERBLUEPRINT_LIST]: (state, action) => action.payload,
    [ADD_NEW_CREDITTRANSFERBLUEPRINT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_CREDITTRANSFERBLUEPRINT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_CREDITTRANSFERBLUEPRINT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const creditTransferBlueprintsForm = handleActions(
  {
    [OPEN_CREDITTRANSFERBLUEPRINT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_CREDITTRANSFERBLUEPRINT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_CREDITTRANSFERBLUEPRINT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [CREDITTRANSFERBLUEPRINT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// CREDITCOSTS
export const creditCosts = handleActions(
  {
    [SET_CREDITCOST_LIST]: (state, action) => action.payload,
    [ADD_NEW_CREDITCOST]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_CREDITCOST]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_CREDITCOST]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const creditCostsForm = handleActions(
  {
    [OPEN_CREDITCOST_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_CREDITCOST_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_CREDITCOST]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [CREDITCOST_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// CREDITTRANSFERCOSTS
export const creditTransferCosts = handleActions(
  {
    [SET_CREDITTRANSFERCOST_LIST]: (state, action) => action.payload,
    [ADD_NEW_CREDITTRANSFERCOST]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_CREDITTRANSFERCOST]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_CREDITTRANSFERCOST]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const creditTransferCostsForm = handleActions(
  {
    [OPEN_CREDITTRANSFERCOST_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_CREDITTRANSFERCOST_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_CREDITTRANSFERCOST]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [CREDITTRANSFERCOST_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// ADMINISTRATIVECOSTS
export const administrativeCosts = handleActions(
  {
    [SET_ADMINISTRATIVECOST_LIST]: (state, action) => action.payload,
    [ADD_NEW_ADMINISTRATIVECOST]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_ADMINISTRATIVECOST]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_ADMINISTRATIVECOST]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const administrativeCostsForm = handleActions(
  {
    [OPEN_ADMINISTRATIVECOST_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ADMINISTRATIVECOST_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_ADMINISTRATIVECOST]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ADMINISTRATIVECOST_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// APPLICABLEDISCOUNTS
export const applicablediscounts = handleActions(
  {
    [SET_APPLICABLEDISCOUNT_LIST]: (state, action) => action.payload,
    [ADD_NEW_APPLICABLEDISCOUNT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_APPLICABLEDISCOUNT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_APPLICABLEDISCOUNT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const applicablediscountsForm = handleActions(
  {
    [OPEN_APPLICABLEDISCOUNT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_APPLICABLEDISCOUNT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_APPLICABLEDISCOUNT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [APPLICABLEDISCOUNT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// PAYMENTPAGES
export const paymentPages = handleActions(
  {
    [SET_PAYMENTPAGE_LIST]: (state, action) => action.payload,
    [ADD_NEW_PAYMENTPAGE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_PAYMENTPAGE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_PAYMENTPAGE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const paymentPagesForm = handleActions(
  {
    [OPEN_PAYMENTPAGE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_PAYMENTPAGE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_PAYMENTPAGE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [PAYMENTPAGE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// CONFIGURATIONS
export const configurations = handleActions(
  {
    [SET_CONFIGURATION_LIST]: (state, action) => action.payload,
    [ADD_NEW_CONFIGURATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_CONFIGURATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_CONFIGURATION]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const configurationsForm = handleActions(
  {
    [OPEN_CONFIGURATION_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_CONFIGURATION_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_CONFIGURATION]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [CONFIGURATION_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// PAYMENTSHEETS
export const paymentSheets = handleActions(
    {
        [SET_PAYMENTSHEET_LIST]: (state, action) => action.payload,
        [ADD_NEW_PAYMENTSHEET]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_PAYMENTSHEET]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_PAYMENTSHEET]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const paymentSheetsForm = handleActions(
    {
        [OPEN_PAYMENTSHEET_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_PAYMENTSHEET_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_PAYMENTSHEET]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [PAYMENTSHEET_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// SCHOLARSHIPS
export const scholarships = handleActions(
    {
        [SET_SCHOLARSHIP_LIST]: (state, action) => action.payload,
        [ADD_NEW_SCHOLARSHIP]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_SCHOLARSHIP]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_SCHOLARSHIP]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const scholarshipsForm = handleActions(
    {
        [OPEN_SCHOLARSHIP_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_SCHOLARSHIP_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_SCHOLARSHIP]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [SCHOLARSHIP_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// GROUPS
export const groups = handleActions(
    {
        [SET_GROUP_LIST]: (state, action) => action.payload,
        [ADD_NEW_GROUP]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_GROUP]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_GROUP]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const groupsForm = handleActions(
    {
        [OPEN_GROUP_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_GROUP_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_GROUP]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [GROUP_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);
