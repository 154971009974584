export const ROLES = {
  admin: {
    label: "Administración",
    role: "a2470ee4",
  },
  student: {
    label: "Estudiante",
    role: "s5df1sfd54",
  },
  teacher: {
    label: "Profesor",
    role: "zsfd46c25df",
  },
};

export const ROLES_RAW = Object.values(ROLES).map((r) => r.role);

export const ROLE_OPTS = Object.values(ROLES).map((r) => ({
  label: r.label,
  value: r.role,
}));